import { useState } from "react";
import { ReactSVG } from "react-svg";
import showPassIc from "../assets/basil_eye-outline.svg";
import hidePassIc from "../assets/basil_eye_closed-outline.svg";
import checkIc from "../assets/check-ic.svg";

function AuthPassInput({ name, placeholder = "", isValid = false, onChange }) {
    const [showPassword, setShowPassword] = useState(false);

    const changeHandler = (event) => {
        if (typeof onChange === "function") {
            onChange(event);
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(prev => !prev);
    }

    return (
        <>
            <input
                type={showPassword ? "text" : "password"}
                name={name}
                placeholder={placeholder}
                onChange={changeHandler}
                required
            />

            {isValid && (
                <ReactSVG src={checkIc}/>
            )}

            <button type="button" onClick={toggleShowPassword}>
                <ReactSVG src={showPassword ? hidePassIc : showPassIc} aria-label="Toggle show password"/>
            </button>
        </>
    )
}

export default AuthPassInput;