class RequestClient {
    #debugEnabled;

    constructor (debugEnabled) {
        this.#debugEnabled = debugEnabled;
    }

    async delete (route, headers) {
        try {
            const response = await fetch(route, {
                method: "DELETE",
                headers,
            });

            return await this.#resultFromResponse(response);
        }
        catch (error) {
            return this.#resultFromError(error);
        }
    }

    async get (route, headers) {
        try {
            const response = await fetch(route, {
                method: "GET",
                headers,
            });

            return await this.#resultFromResponse(response);            
        }
        catch (error) {
            return this.#resultFromError(error);
        }
    }

    async patch (route, headers, body) {
        try {
            const response = await fetch(route, {
                method: "PATCH",
                headers,
                body,
            });

            return await this.#resultFromResponse(response);
        }
        catch (error) {
            return this.#resultFromError(error);
        }
    }

    async post (route, headers, body) {
        try {
            const response = await fetch(route, {
                method: "POST",
                headers,
                body,
            });

            return await this.#resultFromResponse(response);
        }
        catch (error) {
            return this.#resultFromError(error);
        }
    }

    #resultFromError (error) {
        const result = {
            success: false,
            statusCode: -1,
            message: "FetchFailed",
            error,
        }

        if (this.#debugEnabled) {
            console.error("Fetch failed", error);
        }

        return result;
    }

    async #resultFromResponse (response) {
        const success = [200, 201].includes(response.status);
        const resJson = await response.json();

        const result = {
            response: resJson,
            statusCode: response.status,
            success,
        };

        if (!success) {
            result.message = resJson.message;
        }

        return result;
    }
}

export default RequestClient;