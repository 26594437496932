import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AnalysisFormData from "./AnalysisFormData";
import uploadIcon from "../../assets/upload-cloud.svg";
import xCircleIcon from "../../assets/x-circle.svg";

function UploadStep({ data, setData }) {
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(data.selectedTab ?? 0);
    const [rejections, setRejections] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (files) => {
            const userFiles = data.userFiles;
            const inputMode = AnalysisFormData.INPUT_FILES;

            files.forEach(droppedFile => {
                if (!userFiles.some(f => f.name === droppedFile.name)) {
                    userFiles.push(droppedFile);
                }
            });

            setData(prev => ({ ...prev, inputMode, userFiles }));
        },
        onDropRejected: (rejections) => {
            setRejections(prev => [ ...prev, ...rejections ]);
        },
        onError: (error) => {
            console.error(error);
        },
        validator: (file) => {
            const errors = [];

            if (!AnalysisFormData.SUPPORTED_MIMES.includes(file.type)) {
                errors.push({
                    code: "file-invalid-type",
                    message: t("InvalidFileType"),
                });
            }

            if (file.size > AnalysisFormData.MAX_FILE_SIZE) {
                errors.push({
                    code: "file-too-large",
                    message: t("FileTooLarge"),
                });
            }

            return errors.length > 0 ? errors : null;
        }
    });

    const fileLimitExceeded = useMemo(() => {
        return data.userFiles.length > AnalysisFormData.MAX_FILE_COUNT;
    }, [data]);

    const clearFiles = () => {
        setRejections([]);
        setData(prev => ({ ...prev, userFiles: []}));
    }

    const isStepCompleted = () => {
        switch (data.inputMode) {
            case AnalysisFormData.INPUT_FILES:
                return data.userFiles.length > 0 && !fileLimitExceeded && rejections.length === 0;
            
            case AnalysisFormData.INPUT_URL:
                return data.websiteUrl.trim() !== "";

            default:
                return false;
        }
    }

    const removeRejection = (index) => {
        rejections.splice(index, 1);
        setRejections([ ...rejections ]);
    }

    const removeFile = (index) => {
        const userFiles = data.userFiles;
        userFiles.splice(index, 1);
        setData(prev => ({ ...prev, userFiles }));
    }

    const tabSelectionHandler = (selectedTab, lastTab, event) => {
        const inputMode = event.target.value;

        if (selectedTab !== lastTab) {
            setTabIndex(selectedTab);
            setData(prev => ({ ...prev, inputMode, selectedTab }));

            if (inputMode !== AnalysisFormData.INPUT_FILES) {
                clearFiles();
            }
        }
    }

    const urlInputChangeHandler = (event) => {
        const websiteUrl = event.target.value;
        const inputMode = AnalysisFormData.INPUT_URL;
        setData(prev => ({ ...prev, inputMode, websiteUrl }));
    }

    return (
        <div>
            <div className="px-6">
                <Tabs selectedIndex={tabIndex} onSelect={tabSelectionHandler}>
                    <div className="flex justify-center py-4">
                        <TabList className="rounded-tab-list uppercase">
                            <Tab value={AnalysisFormData.INPUT_FILES}>
                                {t('File')}
                            </Tab>
                            <Tab value={AnalysisFormData.INPUT_URL}>
                                {t('URL')}
                            </Tab>
                        </TabList>
                    </div>

                    <TabPanel>
                        <div className="upload-step-label upload-step-dropzone" {...getRootProps()}>
                            <ReactSVG src={uploadIcon} className='text-fucsia-def'/>
                            <div className="text-center">
                                <p className="font-bold text-lg">
                                    {t('DropFilesLabel')}
                                </p>
                                <p className="text-sm">
                                    {t('MaxFileSize')}: {AnalysisFormData.MAX_FILE_SIZE / 1024}KB
                                </p>
                                <p className="text-sm">
                                    {t('SupportedFiles')}: HTM, HTML, SHTML
                                </p>
                            </div>
                            <input {...getInputProps()} />
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="upload-step-label">
                            <input 
                                type="url" 
                                name="websiteUrl" 
                                value={data.websiteUrl}
                                placeholder={t('InsertURL')}
                                className="upload-step-url-field"
                                onChange={urlInputChangeHandler}
                            />
                        </div>
                    </TabPanel>
                </Tabs>

                <ul>
                    {data.userFiles.map((file, index) => (
                        <li key={index} className="upload-step-file">
                            <div>
                                <span className="block font-medium">{file.name}</span>
                                <span className="block text-xs italic">{file.type}</span>
                            </div>
                            <button type="button" onClick={() => removeFile(index)}>
                                <ReactSVG src={xCircleIcon} title="Remove" className='text-fucsia-def'/>
                            </button>
                        </li>
                    ))}
                </ul>

                <ul className="text-red-def">
                    {rejections.map((rej, index) => (
                        <li key={index} className="upload-step-file">
                            <div>
                                <span className="block font-medium">
                                    {rej.file?.name}
                                </span>
                                <ul className="italic text-sm">
                                    {rej.errors?.map(error => (
                                        <li key={error.code}>{error.message}</li>
                                    ))}
                                </ul>
                            </div>
                            <button type="button" onClick={() => removeRejection(index)}>
                                <ReactSVG src={xCircleIcon} title="Remove" className='text-fucsia-def'/>
                            </button>
                        </li>
                    ))}
                </ul>

                {fileLimitExceeded && (
                    <div className="font-semibold mt-4 rounded-lg text-red-def">
                        {t("FileLimitExceeded")}
                    </div>
                )}
            </div>
            <div className="border-t flex flex-wrap gap-3 mt-6 p-6">
                <button type="submit" disabled={!isStepCompleted()} className="btn btn-primary ms-auto rounded-lg">
                    <span className="inline-block p-1 font-semibold">
                        {t("Next")}
                    </span>
                </button>
            </div>
        </div>
    );
}

export default UploadStep;