import { Fragment } from 'react';

function TextFormatter({ text = "" }) {
    const lines = text.split('\n');

    return lines.map((line, i) => {
        const isLineEmpty = line.trim() === '';
        const isPreviousLineEmpty = i > 0 && lines[i - 1].trim() === '';
        
        return (
            <Fragment key={i}>
                {line.split(/(\*\*[^*]+\*\*|\*[^*]+\*|\[.+?\]\(.+?\))/g).map((part, j) => {
                    if (/^\*\*(.+)\*\*$/.test(part)) {
                        return <strong key={j}>{part.slice(2, -2)}</strong>;
                    }
                    if (/^\*(.+)\*$/.test(part)) {
                        return <em key={j}>{part.slice(1, -1)}</em>;
                    }
                    return part;
                })}

                {(!isLineEmpty || (isLineEmpty && !isPreviousLineEmpty)) && i < lines.length - 1 && <br />}
            </Fragment>
        );
    });
}

export default TextFormatter;