import { useEffect, useState } from "react";
import { useGlobalContext } from "../context/Context";
import { useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import DialogOpen from "./DialogOpen";
import closeIc from "../assets/x-ic.svg";

function PageContainer({ title, children }) {
    const { apiClient } = useGlobalContext();
    const { state } = useLocation();
    const { t } = useTranslation();
    const [alert, setAlert] = useState();

    const closeAlert = () => {
        setAlert(null);
    }

    useEffect(() => {
        const appName = apiClient.appName;
        document.title = title ? `${appName} | ${title}` : appName;
        window.scrollTo(0, 0);

        if (state?.showAlert) {
            setAlert(state.message);
        }
    }, [apiClient, state, title]);

    return (
        <main className='main-layout'>
            {children}

            {alert && (
                <DialogOpen>
                    <button type="button" onClick={closeAlert} className="dialog-close-btn">
                        <ReactSVG src={closeIc} title="Close"/>
                    </button>
                    <div className="dialog-body">
                        <h2 className="font-medium py-3 px-6 text-center text-lg">
                            {t(alert)}
                        </h2>
                    </div>
                    <div className="dialog-options border-t text-sm">
                        <button type="button" onClick={closeAlert} className="btn btn-primary">
                            <span className="block font-semibold py-1">
                                {t("Confirm")}
                            </span>
                        </button>
                    </div>
                </DialogOpen>
            )}
        </main>
    );
};

export default PageContainer;