import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import searchIc from "../../assets/bitcoin-icons_search-filled.svg";

function AnalysisFilterForm({ setFilter, searchInput, setSearchInput }) {
    const { t } = useTranslation();

    const radioChangeHandler = (event) => {
        if (typeof setFilter === "function") {
            setFilter(event.target.value);
        }
    }

    const searchInputChangeHandler = (event) => {
        if (typeof setSearchInput === "function") {
            setSearchInput(event.target.value);
        }
    }

    const submitHandler = (event) => {
        event.preventDefault();
    }

    return (
        <form onSubmit={submitHandler} className="flex flex-col sm:flex-row justify-between items-center gap-3">
            <div className="analysis-filters">
                <label>
                    <input
                        type="radio"
                        name="filter"
                        value="all"
                        onChange={radioChangeHandler}
                        defaultChecked
                    />
                    <span>{t("All")}</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="filter"
                        value="html"
                        onChange={radioChangeHandler}
                    />
                    <span>HTML</span>
                </label>
                <label>
                    <input
                        type="radio"
                        name="filter"
                        value="url"
                        onChange={radioChangeHandler}
                    />
                    <span>URL</span>
                </label>
            </div>

            <div className="analysis-searchbar">
                <label>
                    <input
                        type="text"
                        name="search"
                        value={searchInput}
                        placeholder={t("Search")}
                        onChange={searchInputChangeHandler}
                    />
                    <ReactSVG src={searchIc} aria-label="Search" className="cursor-pointer"/>
                </label>
            </div>
        </form>
    );
}

export default AnalysisFilterForm;