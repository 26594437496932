import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function GaugeChart ({ rating = 50, maxRating = 100, children }) {
    const data = {
        labels: Array.from({ length: maxRating }, (_, i) => i.toString()),
        datasets: [{
            data: Array(maxRating).fill(1),
            backgroundColor: Array(maxRating).fill().map((_, i) => {
                const value = i / maxRating;
                const hue = (value * 120).toString(10);
                return `hsl(${hue}, 90%, 50%)`;
            }),
            borderWidth: 0,
        }]
    };

    const options = {
        responsive: true,
        cutout: '70%',
        circumference: 275,
        rotation: 222,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
        }
    };

    const plugin = {
        afterDraw: (chart) => {
            const { ctx, chartArea: { width, height } } = chart;
            const value = rating + 48;
            const angle = (value / maxRating) * (275 / 360) * 2 * Math.PI;
            const cx = width / 2;
            const cy = (height / 2) + 15;
            const needleLength = height / 2.5;
            const needleWidth = 5;
            
            ctx.save();
            ctx.translate(cx, cy);
            ctx.rotate(angle);
            ctx.beginPath();
            ctx.moveTo(0, -needleWidth / 2);
            ctx.lineTo(needleLength, 0);
            ctx.lineTo(0, needleWidth / 2);
            
            ctx.fillStyle = '#444';
            ctx.fill();
            ctx.restore();
        }
    };

    return (
        <div>
            <Doughnut data={data} options={options} plugins={[plugin]} />
            <div className='text-center relative'>
                {children}
            </div>
        </div>
    );
}

export default GaugeChart;