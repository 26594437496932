function useSliderHelper(numItems, startPos) {
    const duration = 500;
    const next = "NEXT";
    const prev = "PREV";

    const initialState = {
        pos: startPos === -1 ? numItems - 1 : startPos,
        sliding: false,
        dir: next,
    }

    const getContainerStyle = (dir, sliding) => {
        const slideDirection = dir === prev ? 'translateX(calc(2 * (-80% - 20%)))' : 'translateX(0%)';

        return {
            display: 'flex',
            transition: sliding ? 'none' : `transform ${duration}ms ease`,
            transform: !sliding ? 'translateX(calc(-80% - 20%))' : slideDirection,
        };
    }

    const getOrder = (index, pos) => {
        return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
    }
    
    const getSlotStyle = (order, isVisible) => ({
        display: 'flex',
        flex: '1 0 100%',
        flexBasis: '90%',
        margin: '0 5%',
        order,
        opacity: isVisible ? (order === 1 ? '1' : '.6') : '0',
        transform: order === 1 ? 'scale(1)' : 'scale(.9)',
        transition: `transform ${duration}ms ease, opacity ${duration}ms ease`,
    });

    const reducer = (state, { type, numItems }) => {
        switch (type) {
            case "reset":
                return initialState;
            case prev:
                return {
                    ...state,
                    dir: prev,
                    sliding: true,
                    pos: state.pos === 0 ? numItems - 1 : state.pos - 1
                };
            case next:
                return {
                    ...state,
                    dir: next,
                    sliding: true,
                    pos: state.pos === numItems - 1 ? 0 : state.pos + 1
                }
            case "stopSliding":
                return { ...state, sliding: false };
            default: 
                return state;
        }
    }

    return {
        next,
        prev,
        initialState,
        getContainerStyle,
        getOrder,
        getSlotStyle,
        reducer
    }
}

export default useSliderHelper;