import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import coverBg from "../assets/about-bg.svg";
import aboutWords from "../assets/about-words.svg";
import ale from "../assets/team/ale.jpeg";
import io from "../assets/team/io.jpg";
import giulia from "../assets/team/giulia.jpeg";
import luca from "../assets/team/luca.jpeg";
import micaela from "../assets/team/micaela.jpeg";
import missionBg from "../assets/mission-bg.svg";

function AboutPage() { 
    const { t } = useTranslation();

    return (
        <PageContainer title={t("About")}>
            <section className='pt-12 overflow-x-hidden'>
                <div className='max-w-7xl mx-auto px-6 relative text-center'>
                    <div className='max-w-4xl mx-auto'>
                        <h1 className='font-semibold text-center text-3xl sm:text-5xl'>
                            {t('AboutTitle')}
                        </h1>
                        <p className='mt-6 sm:mt-12 text-gray-def text-lg sm:text-xl'>
                            {t("AboutCaption")}
                        </p>
                    </div>
                    <div className='flex justify-center items-center mt-6 overflow-x-visible w-full'>
                        <img src={aboutWords} alt='Keywords' className='about-words'/>
                    </div>
                </div>
                <div className='overflow-hidden'>
                    <div className='bg-no-repeat bg-cover lg:bg-auto bg-top h-36 lg:h-96 top-6 sm:top-1 relative' style={{backgroundImage: `url(${coverBg})`}}></div>
                </div>
            </section>

            <section className='banner-fucsia py-12'>
                <div className='container-def px-6'>
                    <h2 className='font-semibold text-center text-3xl sm:text-4xl'>
                        {t('OurTeam')}
                    </h2>

                    <div className='grid grid-cols-2 md:grid-cols-5 justify-between w-fit mx-auto md:w-full gap-6 pt-12'>
                        <div className='team-box'>
                            <figure>
                                <img src={ale} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Alessia Laganà
                            </p>
                            <p>
                                UX/UI Designer
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={io} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Andrea Sala
                            </p>
                            <p>
                                Developer
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={giulia} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Giulia Salvo
                            </p>
                            <p>
                                UX/UI Designer <br/>
                                Project manager
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={luca} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Luca Virzì
                            </p>
                            <p>
                                UX/UI Designer
                            </p>
                        </div>
                        <div className='team-box'>
                            <figure>
                                <img src={micaela} alt='' className='cover-img'/>
                            </figure>
                            <p className='font-semibold text-lg pt-2'>
                                Micaela Kate Iniego
                            </p>
                            <p>
                                Developer <br/>
                                AI Specialist
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-12 relative overflow-hidden'>
                <div className='absolute hidden lg:block inset-0 bg-cover bg-center' style={{backgroundImage: `url(${missionBg})`}}></div>
                <div className='max-w-5xl mx-auto pt-12 pb-24 px-6 relative text-center'>
                    <h2 className='font-semibold text-3xl sm:text-4xl'>
                        {t("OurMission")}
                    </h2>
                    <div className='pt-6 relative'>
                        <p className='max-w-2xl mx-auto'>
                            {t("OurMissionDescription")}
                        </p>
                    </div>
                    <div className='flex flex-col gap-12 max-w-2xl mx-auto mt-12'>
                        <p>
                            {t("OurMissionPar1")}
                        </p>
                        <p>
                            {t("OurMissionPar2")}
                        </p>
                    </div>
                </div>
            </section>
        </PageContainer>
    );
}

export default AboutPage;