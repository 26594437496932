import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next"
import { useGlobalContext } from "../../context/Context";
import PageContainer from "../../components/PageContainer";
import AnalysisAside from "../../sections/analysis/AnalysisAside";
import AnalysisContent from "../../sections/analysis/AnalysisContent";
import AnalysisMetaTag from "../../sections/analysis/AnalysisMetaTag";
import AnalysisHtml from "../../sections/analysis/AnalysisHtml";
import AnalysisUrl from "../../sections/analysis/AnalysisUrl";
import AnalysisTextSuggestions from "../../sections/analysis/AnalysisTextSuggestions";
import AnalysisSocialTags from "../../sections/analysis/AnalysisSocialTags";
import DialogOpen from "../../components/DialogOpen";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import loadingGif from "../../assets/loading.gif";
import checkIc from "../../assets/check-ic.svg";
import closeIc from "../../assets/x-ic.svg";

function AnalysisShowPage() {
    const { apiClient, authReady } = useGlobalContext();
    const { t } = useTranslation();
    const { id } = useParams();
    const [analysisData, setAnalysisData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const [nameField, setNameField] = useState("");
    const [wsFailed, setWsFailed] = useState(false);
    const navigate = useNavigate();
    const displayedName = analysisData?.name || id;
    const isInProgress = analysisData?.status === 0;
    const analysisresult = analysisData?.result;
    const texts = analysisData?.texts;

    const siteOptions = useMemo(() => {
        if (!analysisData) {
            return null;
        }

        const opt = { ...analysisData.siteOptions };
        
        if (analysisData.files.length > 0) {
            opt.targetPage = analysisData.files[0].originalname;
        }

        if (analysisData.url) {
            opt.targetPage = analysisData.url;
        }

        return opt;
    }, [analysisData]);

    const fetchAnalysis = useCallback(() => {
        setLoading(true);

        apiClient.showAnalysis(id).then(result => {
            setLoading(false);

            if (!result.success || !result.response) {
                setError(result.message);
                return;
            }

            const data = result.response.data;
            localStorage.setItem("lastAnalysisData", JSON.stringify({ id, ...data }));
            setAnalysisData(data);
        });
    }, [apiClient, id]);

    const repeatAnalysis = () => {
        if (!loading) {
            setLoading(true);

            apiClient.repeatAnalysis(id).then(result => {
                setLoading(false);

                if (result.statusCode === 402) {
                    navigate("/pricing");
                    return;
                }

                if (!result.success || !result.response) {
                    setError(result.message);
                    return;
                }

                setAnalysisData(result.response.data);
            });
        }
    }

    const toggleRename = () => {
        if (showRename) {
            setShowRename(false);
            return;
        }

        setNameField(displayedName);
        setShowRename(true);
    }

    const renameSubmitHandler = (event) => {
        event.preventDefault();
        setShowRename(false);
        
        if (!loading && nameField !== displayedName) {
            setLoading(true);

            apiClient.renameAnalysis(id, nameField).then(result => {
                setLoading(false);

                if (!result.success) {
                    setError(result.message);
                    return;
                }

                fetchAnalysis();
            });
        }
    }

    useEffect(() => {
        if (!analysisData) {
            const lastAnalysisData = JSON.parse(localStorage.getItem("lastAnalysisData"));
    
            if (lastAnalysisData && lastAnalysisData.id === id) {
                setAnalysisData(lastAnalysisData);

                if (lastAnalysisData.name) {
                    setNameField(lastAnalysisData.name);
                }
            }
        }
    }, [analysisData, id]);

    useEffect(() => {
        let ws;

        if (authReady) {
            ws = apiClient.openWebSocket();

            ws.addEventListener("message", (event) => {
                const message = JSON.parse(event.data);

                if (message.analysisId === id) {
                    fetchAnalysis();
                }
            });

            ws.addEventListener("error", () => {
                setWsFailed(true);
            });
            
            fetchAnalysis();
        }

        return () => {
            ws?.close();
        }
    }, [apiClient, authReady, fetchAnalysis, id]);

    return (
        <PageContainer title={t("Analyses")}>
            <div className="container-def py-12 px-6">
                <div className="flex flex-wrap items-center gap-6 sm:gap-12 pb-12">
                    <Link to="/account/analysis" className="basis-14">
                        <ReactSVG src={backBtnIcon} title="Back"/>
                    </Link>
                    <h1 className="font-bold pb-1 text-3xl sm:text-4xl">
                        {t("Analysis")}
                    </h1>
                </div>
                
                {error && (
                    <p className="mb-6 font-medium">
                        {t(error)}
                    </p>
                )}

                {wsFailed && (
                    <p className="mb-6 font-medium">
                        {t("ConnectionLost")}
                    </p>
                )}

                {analysisData?.status === 2 && !loading && (
                    <div className="flex flex-wrap items-center gap-2 mb-6">
                        <span className="inline-block font-medium">
                            {t(analysisData.message)}
                        </span>
                        <button className="link-styled font-semibold" onClick={repeatAnalysis}>
                            {t("Retry")}
                        </button>
                    </div>
                )}

                {analysisresult && (
                    <div className="space-y-12" aria-disabled={loading}>
                        <AnalysisContent
                            name={displayedName}
                            rating={analysisresult.rating}
                            wordCount={analysisresult.wordCount}
                            textAnalysis={analysisresult.textAnalysis}
                            keywords={analysisresult.keywords}
                            siteOptions={siteOptions}
                            showRename={toggleRename}
                        />

                        <AnalysisMetaTag
                            title={analysisresult.title}
                            metadescription={analysisresult.metadescription}
                            keywords={analysisresult.keywords}
                        />

                        {analysisresult.htmlAnalysis && (
                            <AnalysisHtml htmlAnalysis={analysisresult.htmlAnalysis}/>
                        )}

                        {analysisresult.urlAnalysis?.fromUrl && (
                            <AnalysisUrl urlAnalysis={analysisresult.urlAnalysis}/>
                        )}

                        {analysisresult.htmlAnalysis?.socialTags && (
                            <AnalysisSocialTags socialTags={analysisresult.htmlAnalysis.socialTags}/>
                        )}
                        
                        {texts && texts.length > 0 && (
                            <AnalysisTextSuggestions
                                texts={texts}
                                isInProgress={isInProgress}
                                repeatAnalysis={repeatAnalysis}
                            />
                        )}
                    </div>
                )}

                {isInProgress && !analysisresult && (
                    <div className="analysis-box bg-white flex flex-col justify-center items-center gap-8 mt-6 px-6 py-8">
                        <h2 className="font-semibold text-2xl sm:text-3xl text-center">
                            {t("AnalysisInProgressTitle")}
                        </h2>
                        <img src={loadingGif} alt="Loading" className="max-w-20"/>
                        <ul className="flex flex-col items-center gap-4">
                            <li className="flex items-center gap-2">
                                <ReactSVG src={checkIc}/>
                                <span>{t("ReadingHTML")}</span>
                            </li>
                            <li className="flex items-center gap-2">
                                <ReactSVG src={checkIc}/>
                                <span>{t("AnalyzingSeo")}</span>
                            </li>
                            <li className="flex items-center gap-2">
                                <ReactSVG src={checkIc}/>
                                <span>{t("StudyingCopy")}</span>
                            </li>
                        </ul>
                    </div>
                )}

                {showRename && (
                    <DialogOpen>
                        <button type="button" onClick={toggleRename} className="dialog-close-btn">
                            <ReactSVG src={closeIc} aria-label="Close"/>
                        </button>
                        <form onSubmit={renameSubmitHandler}>
                            <div className="dialog-body max-w-lg w-screen">
                                <label className="analyze-configuration-label">
                                    <h2 className="font-medium py-3 px-6 text-center text-lg">
                                        {t("RenameAnalysis")}
                                    </h2>
                                    <input 
                                        type="text"
                                        name="name"
                                        value={nameField}
                                        onChange={(event) => setNameField(event.target.value)}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="dialog-options border-t text-sm">
                                <button type="button" onClick={toggleRename}>
                                    {t("Cancel")}
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    <span className="block font-semibold py-1">
                                        {t("Confirm")}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </DialogOpen>
                )}
            </div>

            {analysisresult && (
                <AnalysisAside/>
            )}
        </PageContainer>
    );
}

export default AnalysisShowPage