import { Routes, Route } from 'react-router-dom';
import { GlobalProvider } from './context/Context';
import { initializeApp } from 'firebase/app';
import ModalListener from './components/ModalListener';
import Navigation from './sections/Navigation';
import Footer from './sections/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import PricingPage from './pages/PricingPage';
import AnalyzePage from './pages/AnalyzePage';
import AccountPage from './pages/AccountPage';
import EditProfilePage from './pages/account/EditProfilePage';
import UpdateCardPage from './pages/account/UpdateCardPage';
import OrderHistoryPage from './pages/account/OrderHistoryPage';
import ChangePlanPage from './pages/account/ChangePlanPage';
import ChangePasswordPage from './pages/account/ChangePasswordPage';
import EmailSignIn from './pages/auth/EmailSignIn';
import AnalysisListPage from './pages/analysis/AnalysisListPage';
import AnalysisShowPage from './pages/analysis/AnalysisShowPage';
import CookieDeclarationPage from './pages/legal/CookieDeclarationPage';
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import TermsConditionsPage from './pages/legal/TermsConditionsPage';
import ApiClient from './clients/ApiClient';
import AuthClient from './clients/AuthClient';
import firebaseConfig from './resources/firebase-config.json';

import './App.css';

function App() {
    const fbApp = initializeApp(firebaseConfig);
    const authClient = new AuthClient(fbApp);
    const apiClient = new ApiClient();

    return (
        <GlobalProvider authClient={authClient} apiClient={apiClient}>
            <div className='app-container'>
                <ModalListener />
                <Navigation />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/analyze" element={<AnalyzePage />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/account/edit" element={<EditProfilePage />} />
                    <Route path='/account/change-password' element={<ChangePasswordPage />} />
                    <Route path="/account/update-card" element={<UpdateCardPage />} />
                    <Route path="/account/order-history" element={<OrderHistoryPage />} />
                    <Route path="/account/change-plan" element={<ChangePlanPage />} />
                    <Route path="/account/analysis" element={<AnalysisListPage />} />
                    <Route path="/account/analysis/:id" element={<AnalysisShowPage />} />
                    <Route path='/auth/email-sign-in' element={<EmailSignIn />} />
                    <Route path='/cookie-declaration' element={<CookieDeclarationPage />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
                    <Route path='/terms-conditions' element={<TermsConditionsPage />} />
                </Routes>
                <Footer />
            </div>
        </GlobalProvider>
    );
}

export default App;
