import { useEffect, useRef, useState } from 'react';

function Dropdown({ label, isActive = false, children }) {
    const [dropdown, setDropdown] = useState(false);
    const ref = useRef();

    const toggleDropdown = () => {
        setDropdown(prev => !prev);
    }

    useEffect(() => {
        const handler = (event) => {
            if (dropdown && ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        }

        document.addEventListener("click", handler);
        document.addEventListener("touchstart", handler);

        return () => {
            document.removeEventListener("click", handler);
            document.removeEventListener("touchstart", handler);
        }
    }, [dropdown]);

    return (
        <div className="relative overflow-visible" ref={ref}>
            <span role='button' className="dropdown-label" onClick={toggleDropdown} data-active={dropdown || isActive}>{label}</span>
            { dropdown && (
                <div className='absolute dropdown-menu mt-2 origin-top-right py-1 right-0' onClick={toggleDropdown}>
                    {children}
                </div>
            )}
        </div>
    )
};

export default Dropdown;
