import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import PageContainer from "../../components/PageContainer";
import DialogOpen from "../../components/DialogOpen";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import closeIc from "../../assets/x-ic.svg";

function ChangePlanPage() {
    const { apiClient, authReady, getDateString } = useGlobalContext();
    const { t } = useTranslation();
    const [activeSubs, setActiveSubs] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [cancellingSub, setCancellingSub] = useState();
    const navigate = useNavigate();

    const cancelSub = () => {
        const userSubId = cancellingSub;

        setLoading(true);
        setCancellingSub(null);
        
        apiClient.cancelSubscription(userSubId).then(result => {
            setLoading(false);

            if (!result.success) {
                setError(result.message);
                return;
            }

            navigate("/account");
        });
    }

    const closeCancelPlanConfirm = () => {
        setCancellingSub(null);
    }

    useEffect(() => {
        const sessionSubs = JSON.parse(sessionStorage.getItem("activeSubs"));

        if (Array.isArray(sessionSubs)) {
            setActiveSubs(sessionSubs);
            return;
        }
        
        if (authReady) {
            setLoading(true);

            apiClient.getActivePlans().then(result => {
                setLoading(false);

                if (!result.success || !result.response) {
                    setError(result.message);
                    return;
                }
    
                const activeSubs = result.response.subscriptions;
    
                if (Array.isArray(activeSubs)) {
                    setActiveSubs(activeSubs);
                    sessionStorage.setItem("activeSubs", JSON.stringify(activeSubs));
                }
            });
        }
        }, [authReady, apiClient]);
        
        return (
            <PageContainer title={t("ChangePlan")}>
                <section className='container-def px-6 py-12'>
                    <div className='flex flex-col sm:flex-row gap-6 sm:gap-12 relative'>
                        <div className="basis-14">
                            <Link to="/account">
                                <ReactSVG src={backBtnIcon} title="Back"/>
                            </Link>
                        </div>

                        <div className="flex-1">
                            <h1 className="font-bold sm:mb-6 text-2xl sm:text-4xl hidden">
                                {t('ChangePlan')}
                            </h1>

                            {error && (
                                <p className="py-2 mb-6 text-fucsia-def font-medium">
                                    {t(error)}
                                </p>
                            )}

                            <ul>
                                {activeSubs.map((item, index) => (
                                    <li key={index}>
                                        <div className="account-plans-item p-6 max-w-2xl">
                                            <h2 className='text-sm mb-2'>
                                                {t('YourPlan')}
                                            </h2>
                                            <h3 className='text-fucsia-def font-bold mb-3 text-2xl'>
                                                {t(item.name)}
                                            </h3>
                                            <p className='font-semibold text-lightblue-def mb-3'>
                                                {t("RemainingCredits").replace("%n%", item.token_count)}
                                            </p>
                                            <div className='flex flex-row flex-wrap gap-3 justify-between items-end'>
                                                {!item.deletedAt ? (
                                                    <p className='text-sm text-gray-def'>
                                                        {t("NextInvoice").replace("%price%", item.price).replace("%date%", getDateString(item.expiry_date))}<br/>
                                                    </p>
                                                ) : (
                                                    <p className='text-sm text-red-def'>
                                                        {t("SubscriptionCanceled").replace("%date%", getDateString(item.expiry_date))}<br/>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        
                                        {!item.deletedAt && (
                                            <div className="pb-4 flex flex-wrap gap-6" aria-disabled={loading}>
                                                <Link to="/pricing" className='btn btn-primary rounded-lg'>
                                                    <span className="block font-bold p-1">
                                                        {t('ChangePlan')}
                                                    </span>
                                                </Link>
                                                <button type="button" onClick={() => setCancellingSub(item.id)}>
                                                    {t('CancelPlan')}
                                                </button>
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>

                {cancellingSub && (
                    <DialogOpen>
                        <button type="button" onClick={closeCancelPlanConfirm} className="dialog-close-btn">
                            <ReactSVG src={closeIc} title="Close"/>
                        </button>
                        <div className="dialog-body max-w-lg text-center w-screen">
                            <h2 className="font-medium py-3 px-6 text-lg">
                                {t("CancelPlan")}
                            </h2>
                            <p>{t("CancelPlanDescription")}</p>
                        </div>
                        <div className="dialog-options border-t text-sm">
                            <button type="button" onClick={closeCancelPlanConfirm}>
                                {t("Cancel")}
                            </button>
                            <button type="button" onClick={cancelSub} className="btn bg-red-800 text-white">
                                <span className="block font-semibold py-1">
                                    {t("Confirm")}
                                </span>
                            </button>
                        </div>
                    </DialogOpen>
                )}
            </PageContainer>
    );
}

export default ChangePlanPage;