import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../context/Context';
import PageContainer from '../components/PageContainer';
import LoadingSkeleton from '../components/LoadingSkeleton';
import warnIc from '../assets/warn-msg-ic.svg';
import infoIc from '../assets/info-msg-ic.svg';
import arrowNextIc from '../assets/next-arrow-ic.svg';

function AccountPage() {
    const { authClient, apiClient, authReady, loggedUser, currentProviders, getDateString } = useGlobalContext();
    const { t } = useTranslation();
    const { state } = useLocation();
    const [showVerificationWarn, setShowVerificationWarn] = useState(false);
    const [message, setMessage] = useState(state?.message);
    const [activeSubs, setActiveSubs] = useState([]);
    const [activePkgs, setActivePkgs] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const profileLabel = useMemo(() => {
        if (loggedUser) {
            const dName = loggedUser.displayName || loggedUser.email;
            return dName[0];
        }
        
        return null;
    }, [loggedUser]);

    const plansEmpty = useMemo(() => {
        return activeSubs.length === 0 && activePkgs.length === 0;
    }, [activeSubs, activePkgs]);

    const verifyEmail = () => {
        setShowVerificationWarn(false);

        authClient.verifyEmail().then(result => {
            if (result.success) {
                setMessage("VerificationEmailSent");
                return;
            }
            
            setMessage(result.message);
        });
    }

    const logout = () => {
        authClient.logout().then(result => {
            if (result.success) {
                navigate("/");
            }
        });
    }

    useEffect(() => {
        if (authReady && loggedUser) {
            if (!loggedUser.emailVerified) {
                setShowVerificationWarn(true);
            }

            setLoading(true);

            apiClient.getActivePlans().then(result => {
                setLoading(false);

                if (!result.success || !result.response) {
                    setMessage(result.message);
                    return;
                }

                setActivePkgs(result.response.packages);
                setActiveSubs(result.response.subscriptions);
                sessionStorage.setItem("activeSubs", JSON.stringify(result.response.subscriptions));
            });
        }
    }, [authReady, apiClient, loggedUser, navigate]);

    return (
        <PageContainer title={t("Account")}>
            <div className='container-def px-6'>
                <h1 className='font-medium px-3 py-12 text-3xl sm:text-4xl text-center hidden'>
                    {t('Account')}
                </h1>
                
                <section className='flex flex-col sm:flex-row gap-6 justify-start items-center pt-12'>
                    <figure className='profile-picture w-24'>
                        <span>{profileLabel}</span>
                    </figure>

                    {loggedUser && (
                        <div className='px-3 relative space-y-0.5 text-center sm:text-start sm:bottom-1'>
                            <p className='text-base sm:text-lg'>
                                {loggedUser.displayName}
                            </p>
                            <p className='text-sm sm:text-base'>
                                {loggedUser.email}
                            </p>
                            <button onClick={logout} disabled={loading} className='link-styled font-medium text-fucsia-def'>
                                {t("LogoutAccount")}
                            </button>
                        </div>
                    )}
                </section>

                <section className='py-12'>
                    <div className='space-y-1'>
                        {showVerificationWarn && (
                            <div className='flex flex-row gap-3 items-center py-2'>
                                <ReactSVG src={warnIc}/>
                                <p className='flex flex-row flex-wrap gap-x-2 font-medium text-sm'>
                                    <span className='inline-block'>
                                        {t('EmailNotVerified')}.
                                    </span>
                                    <button className='text-start link-styled font-semibold' onClick={verifyEmail}>
                                        {t('VerifyEmail')}
                                    </button>
                                </p>
                            </div>
                        )}

                        {message && (
                            <div className='flex flex-row gap-3 items-center py-2'>
                                <ReactSVG src={infoIc}/>
                                <p className='font-medium text-sm'>
                                    <span className='inline-block'>
                                        {t(message)}
                                    </span>
                                </p>
                            </div>
                        )}
                    </div>

                    <div className='flex flex-col sm:flex-row flex-wrap gap-6 pt-6'>
                        <div className='sm:basis-1/3 account-links sm:order-1'>
                            <Link to="/account/edit">
                                <span className='font-medium'>
                                    {t('Profile')}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>

                            {currentProviders.includes("password") && (
                                <Link to="/account/change-password">
                                    <span className='font-medium'>
                                        {t('ChangePassword')}
                                    </span>
                                    <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                                </Link>
                            )}

                            <Link to="/account/analysis">
                                <span className='font-medium'>
                                    {t("YourAnalysis")}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>
                            <Link to="/account/order-history">
                                <span className='font-medium'>
                                    {t('OrderHistory')}
                                </span>
                                <ReactSVG src={arrowNextIc} className='text-lightblue-def'/>
                            </Link>
                        </div>
                        <div className='account-plans sm:border-r sm:flex-1 sm:pr-6'>
                            <ul>
                                {activeSubs.map((item, index) => (
                                    <li key={index} className='account-plans-item'>
                                        <h2 className='text-sm mb-2'>
                                            {t('YourPlan')}
                                        </h2>
                                        <h3 className='text-fucsia-def font-bold mb-3 text-2xl'>
                                            {t(item.name)}
                                        </h3>
                                        <p className='font-semibold text-lightblue-def mb-3'>
                                            {t("RemainingCredits").replace("%n%", item.token_count)}
                                        </p>
                                        <div className='flex flex-row flex-wrap gap-3 justify-between items-end'>
                                            {!item.deletedAt ? (
                                                <p className='text-sm text-gray-def'>
                                                    {t("NextInvoice").replace("%price%", item.price).replace("%date%", getDateString(item.expiry_date))}<br/>
                                                </p>
                                            ) : (
                                                <p className='text-sm text-red-def'>
                                                    {t("SubscriptionCanceled").replace("%date%", getDateString(item.expiry_date))}<br/>
                                                </p>
                                            )}

                                            <Link to="/account/change-plan" className='font-medium text-lg rounded-lg'>
                                                {t('Show')}
                                            </Link>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            <ul>
                                {activePkgs.map((item, index) => (
                                    <li key={index} className='account-plans-item'>
                                        <h2 className='text-sm mb-2'>
                                            {t('YourPackage')}
                                        </h2>
                                        <h3 className='text-fucsia-def font-bold mb-3 text-2xl'>
                                            {t(item.name)}
                                        </h3>
                                        <p className='font-semibold text-lightblue-def mb-3'>
                                            {t("RemainingCredits").replace("%n%", item.token_count)}
                                        </p>
                                        <p className='text-sm text-gray-def'>
                                            {t("PkgExpiry").replace("%date%", getDateString(item.expiry_date))}
                                        </p>
                                    </li>
                                ))}
                            </ul>

                            {plansEmpty && loading && (
                                <div className='p-6 mb-4'>
                                    <LoadingSkeleton className="text-sm mb-2"/>
                                    <LoadingSkeleton className="text-2xl mb-3"/>
                                    <LoadingSkeleton className="mb-3"/>
                                    <LoadingSkeleton className="text-sm"/>
                                </div>
                            )}
                            
                            {plansEmpty && !loading && (
                                <div className='text-center max-w-xl mt-8'>
                                    <p>
                                        {t("EmptyPlans")}
                                    </p>
                                    <Link to="/pricing" className='btn btn-primary mt-8 rounded-lg'>
                                        <span className='block font-semibold p-2 sm:px-3 text-lg sm:text-xl'>
                                            {t("BuyNow")}
                                        </span>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </PageContainer>
    );
};

export default AccountPage;