import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import PageContainer from "../../components/PageContainer";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import cardIcon from "../../assets/quill_creditcard.svg";
import dots from "../../assets/card-dots-ic.svg";

function UpdateCardPage() {
    const { t } = useTranslation();

    return (
        <PageContainer title={t("UpdateCard")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row relative sm:gap-12'>
                    <div className="basis-14">
                        <Link to="/account">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                    </div>
                    <div className="basis-1/2 flex flex-col gap-6">
                        <h1 className="font-bold text-2xl sm:text-4xl">
                            {t('PaymentMethod')}
                        </h1>

                        <p className="font-light">
                            {t('PaymentMethodCaption')}
                        </p>

                        <div className="flex flex-col space-y-6">
                            <h2 className="font-semibold text-xl">
                                {t("MyCards")}
                            </h2>

                            <div className="credit-card">
                                <ReactSVG src={cardIcon}/>
                                <ReactSVG src={dots}/>
                                <p>0000 | MM/AAAA</p>
                                <button type="button">
                                    {t("AddCard")}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="basis-14"></div>
                </div>
            </section>
        </PageContainer>
    );
}

export default UpdateCardPage;