class AnalysisFormData {
    static INPUT_FILES = 0;
    static INPUT_URL = 2;
    static MAX_FILE_COUNT = 1;
    static MAX_FILE_SIZE = 512000;
    static SUPPORTED_MIMES = ["text/html"];

    inputMode;
    websiteUrl;
    userFiles;

    constructor () {
        this.inputMode = AnalysisFormData.INPUT_FILES;
        this.websiteUrl = "";
        this.userFiles = [];
    }

    static create () {
        const instace = new AnalysisFormData();
        return { ...instace };
    }
}

export default AnalysisFormData;