import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

export const GlobalProvider = ({ authClient, apiClient, children }) => {
	const { i18n } = useTranslation();
	const [loggedUser, setLoggedUser] = useState();
	const [authReady, setAuthReady] = useState(false);
	
	const [modalStates, setModalStates] = useState({
		login: false,
		register: false,
		forgotPass: false,
		verificationSent: false,
	});

	const currentProviders = useMemo(() => {
        const providers = [];

        if (loggedUser) {
            loggedUser.providerData?.forEach(pd => {
                providers.push(pd.providerId);
            });
        }

        return providers;
    }, [loggedUser]);

	const getDateString = (dateNum) => {
        return new Date(dateNum).toLocaleDateString();
    }

	const switchModal = (modal) => {
		const newStates = { ...modalStates };
		const keys = Object.keys(modalStates);

		keys.forEach(k => {
			newStates[k] = k === modal;
		});

		setModalStates(newStates);
	}

	useEffect(() => {
		const userLang = localStorage.getItem("lang");

		if (userLang && i18n.language !== userLang) {
			i18n.changeLanguage(userLang);
		}
	}, [i18n]);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(authClient.auth, user => {
			apiClient.updateUser(user);
			setLoggedUser(user);
			setAuthReady(true);

			if (user) {
				apiClient.hookDefaultPackage();
			}
		});

		return () => {
			unsubscribe();
			setAuthReady(false);
		};
	}, [authClient, apiClient]);

  	return (
		<GlobalContext.Provider value={{
			authClient,
			apiClient,
			authReady,
			loggedUser,
			currentProviders,
			modalStates,
			getDateString,
			setLoggedUser,
			switchModal,
			}}>
		{children}
		</GlobalContext.Provider>
	);
};
