import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PageContainer from "../../components/PageContainer";

function TermsConditionsPage() {
    const { t } = useTranslation();
    const containerRef = useRef();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.innerHTML = t("TermsConditionsBody");
        }
    }, [t]);

    return (
        <PageContainer title={t("TermsConditions")}>
            <section className="container-def py-12 px-6">
                <h1 className="font-medium text-2xl sm:text-3xl">
                    {t("TermsConditions")}
                </h1>
                <div className="policy-body" ref={containerRef}>
                </div>
            </section>
        </PageContainer>
    );
}

export default TermsConditionsPage;