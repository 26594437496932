import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import useAnalysisTools from "../../hooks/useAnalysisTools";
import closeIc from "../../assets/x-ic.svg";
import pencilIc from "../../assets/mdi-light_pencil.svg";
import trashIc from "../../assets/trash-ic.svg";
import DialogOpen from "../../components/DialogOpen";

function AnalysisListItem({ doc, onRename, onDelete }) {
    const { t } = useTranslation();
    const { getDateString, getRatingColor, limitString } = useAnalysisTools();
    const [nameField, setNameField] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [showRename, setShowRename] = useState(false);
    const name = doc.data?.name || doc.id;

    const confirmDelete = () => {
        setShowDelete(false);

        if (typeof onDelete === "function") {
            onDelete(doc.id);
        }
    }

    const openRename = () => {
        setNameField(name);
        setShowRename(true);
    }

    const renameSubmitHandler = (event) => {
        event.preventDefault();
        setShowRename(false);
        
        if (typeof onRename === "function" && nameField !== name) {
            onRename(doc.id, nameField);
        }
    }

    const toggleShowDelete = () => {
        setShowDelete(prev => !prev);
    }

    const toggleShowRename = () => {
        setShowRename(prev => !prev);
    }

    return (
        <li>
            <div className="analysis-list-item px-6 py-3 relative" data-toshow={doc.data.toRead}>
                <div className="flex flex-col sm:flex-row gap-2">
                    <div className="flex-1 pt-1">
                        <div className="flex flex-wrap gap-2">
                            <h2 className="font-semibold text-2xl">
                                {name}
                            </h2>
                            <button type="button" onClick={openRename}>
                                <ReactSVG src={pencilIc} aria-label="Rename"/>
                            </button>
                        </div>
                        <div className="mt-2">
                            <p className="break-words">
                                {doc.data.url ? (
                                    <span><b className="font-medium">URL</b> - {limitString(doc.data.url)}</span>
                                ) : (
                                    <span><b className="font-medium">HTML</b></span>
                                )}
                            </p>
                            <p className="font-medium text-gray-def">
                                {getDateString(doc.data.createdAt)}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 pt-3">
                        <div className="analysis-list-item-rating mx-auto w-32 sm:w-20" data-color={getRatingColor(doc.data.rating)}>
                            {doc.data.rating}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between items-center gap-6 mt-6">
                    <button type="button" onClick={toggleShowDelete}>
                        <ReactSVG src={trashIc} aria-label="Delete" className="text-gray-def"/>
                    </button>
                    <Link to={`/account/analysis/${doc.id}`} className="font-semibold text-lg text-fucsia-def underline hover:no-underline">
                        {t("ShowAnalysis")}
                    </Link>
                </div>
            </div>

            {showDelete && (
                <DialogOpen>
                    <button type="button" onClick={toggleShowDelete} className="dialog-close-btn">
                        <ReactSVG src={closeIc} aria-label="Close"/>
                    </button>
                    <div className="dialog-body">
                        <h2 className="font-medium py-3 px-6 text-center text-lg">
                            {t("DeleteAnalysisMessage")}
                        </h2>
                    </div>
                    <div className="dialog-options border-t text-sm">
                        <button type="button" onClick={toggleShowDelete}>
                            {t("Cancel")}
                        </button>
                        <button type="button" onClick={confirmDelete} className="btn btn-primary">
                            <span className="block font-semibold py-1">
                                {t("Confirm")}
                            </span>
                        </button>
                    </div>
                </DialogOpen>
            )}

            {showRename && (
                <DialogOpen>
                    <button type="button" onClick={toggleShowRename} className="dialog-close-btn">
                        <ReactSVG src={closeIc} aria-label="Close"/>
                    </button>
                    <form onSubmit={renameSubmitHandler}>
                        <div className="dialog-body max-w-lg w-screen">
                            <label className="analyze-configuration-label">
                                <h2 className="font-medium py-3 px-6 text-center text-lg">
                                    {t("RenameAnalysis")}
                                </h2>
                                <input 
                                    type="text"
                                    name="name"
                                    value={nameField}
                                    onChange={(event) => setNameField(event.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div className="dialog-options border-t text-sm">
                            <button type="button" onClick={toggleShowRename}>
                                {t("Cancel")}
                            </button>
                            <button type="submit" className="btn btn-primary">
                                <span className="block font-semibold py-1">
                                    {t("Confirm")}
                                </span>
                            </button>
                        </div>
                    </form>
                </DialogOpen>
            )}
        </li>
    );
}

export default AnalysisListItem;