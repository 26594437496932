import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/Context';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import AuthPassInput from '../../components/AuthPassInput';
import GoogleSingInButton from '../../components/GoogleSignInButton';
import closeIc from '../../assets/x-ic.svg';
import xRedIcon from "../../assets/x-circle-red.svg";

function RegisterModal() {
    const { authClient, switchModal } = useGlobalContext();
    const { t } = useTranslation();
    const [authError, setAuthError] = useState();
    const [fields, setFields] = useState({});
    const [keepLogged, setKeepLogged] = useState(false);
    const [loading, setLoading] = useState(false);

    const isPasswordValid = useMemo(() => {
        return fields.password && fields.password.length >= authClient.minPasswordLength;
    }, [fields, authClient]);

    const isPasswordConfirmed = useMemo(() => {
        return fields.passwordConfirmation && fields.password === fields.passwordConfirmation;
    }, [fields]);

    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFields(prev => ({ ...prev, [name]:value }));
    }

    const close = () => {
        switchModal();
    }

    const onRegisterResult = (result) => {
        setLoading(false);

        if (!result.success) {
            setAuthError(result.message);
            return;
        }

        if (result.verificationSent) {
            switchModal("verificationSent");
            return;
        }

        switchModal();
    }

    const submitHandler = (event) => {
        event.preventDefault();
        event.target.reset();
        setLoading(true);
        
        authClient.register(fields.email, fields.password, fields.name, keepLogged)
            .then(onRegisterResult);
    }

    const switchToLogin = () => {
        switchModal("login");
    }

    return (
        <div className='fixed bg-black/75 inset-0 items-center z-50'>
            <div className='modal'>
                <button className='dialog-close-btn' onClick={close}>
                    <ReactSVG src={closeIc} title='Close'/>
                </button>

                <h2 className='font-bold pt-6 pb-4 text-2xl text-center'>
                    {t('CreateAccountTitle')}
                </h2>

                <form className='auth-form' onSubmit={submitHandler}>
                    <div className='auth-form-options'>
                        <GoogleSingInButton disabled={loading} onAuthResult={onRegisterResult}/>
                    </div>

                    <p className='mt-6 text-gray-def'>
                        {t("EmailRegisterLabel")}
                    </p>

                    <label className='auth-form-field'>
                        <span className='auth-form-placeholder'>
                            {t('Username')}
                        </span>
                        <input
                            type='text'
                            name='name'
                            onChange={changeHandler}
                            required
                        />
                    </label>
                    <label className='auth-form-field'>
                        <span className='auth-form-placeholder'>
                            {t('Email')}
                        </span>
                        <input
                            type='email'
                            name='email'
                            onChange={changeHandler}
                            required
                        />
                    </label>
                    <label className='auth-form-field'>
                        <span className='auth-form-placeholder'>
                            {t('Password')}
                        </span>
                        <AuthPassInput
                            name='password'
                            isValid={isPasswordValid}
                            onChange={changeHandler}
                        />
                        {fields.password && !isPasswordValid && (
                            <span className='auth-form-field-message'>
                                <ReactSVG src={xRedIcon}/>
                                {t('PasswordMinCharLabel').replace("%minChars%", authClient.minPasswordLength)}
                            </span>
                        )}
                    </label>
                    <label className='auth-form-field'>
                        <span className='auth-form-placeholder'>
                            {t('ConfirmPassword')}
                        </span>
                        <AuthPassInput
                            name='passwordConfirmation'
                            isValid={isPasswordConfirmed}
                            onChange={changeHandler}
                        />
                        {fields.passwordConfirmation && !isPasswordConfirmed && (
                            <span className='auth-form-field-message'>
                                <ReactSVG src={xRedIcon}/>
                                {t('IncorrectPassword')}
                            </span>
                        )}
                    </label>
                    <div className='auth-form-options'>
                        {authError && (
                            <span className='auth-form-error'>
                                <ReactSVG src={xRedIcon}/>
                                {t(authError)}
                            </span>
                        )}

                        <button type='submit' disabled={loading} className='btn btn-fucsia min-h-14 text-white rounded-xl'>
                            <span className='block font-bold text-base'>
                                {t('CreateAccount')}
                            </span>
                        </button>
                    </div>
                    <div className='auth-form-group font-medium text-sm'>
                        <label>
                            <input
                                type='checkbox'
                                name='keepLogged'
                                className='checkbox-input'
                                checked={keepLogged}
                                onChange={() => setKeepLogged(prev => !prev)}
                            />
                            <span className='cursor-pointer'>
                                {t('KeepLogged')}
                            </span>
                        </label>
                    </div>
                    <div className='text-start space-x-1 mt-6'>
                        <span>
                            {t('AlreadyRegistered')}
                        </span>
                        <button type='button' onClick={switchToLogin} disabled={loading} className='font-medium inline text-lightblue-def underline'>
                            {t('SignInLink')}
                        </button>
                    </div>
                </form>
                <div className='pt-6 space-x-1 text-center text-sm'>
                    <span className='text-gray-def'>
                        {t("RegistrationEnding")}
                    </span>
                    <Link to="/terms-conditions" onClick={close} className='font-medium text-lightblue-def underline'>
                        {t("TermsConditions")}
                    </Link>
                    <span className='text-gray-def'>
                        {t("and")}
                    </span>
                    <Link to="/privacy-policy" onClick={close} className='font-medium text-lightblue-def underline'>
                        {t("PrivacyPolicy")}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default RegisterModal;