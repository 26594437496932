import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import Popover from "../../components/Popover";
import socialAnalysisIc from "../../assets/social-analysis-ic.svg";

function AnalysisSocialTags({ socialTags }) {
    const { t } = useTranslation();

    return (
        <section id="socialAnalysis">
            <div className="analysis-box-title">
                <ReactSVG src={socialAnalysisIc}/>
                <h2 className="font-semibold text-2xl">
                    {t("SocialTags")}
                </h2>
            </div>

            {Object.keys(socialTags).map((tag, index) => (
                <div key={index} className="analysis-result-item">
                    <div className="analysis-result-title">
                        <h3 className="mb-1 font-medium text-xl">
                            {tag.charAt(0).toUpperCase() + tag.slice(1)}
                        </h3>
                        <Popover>
                            {t("SocialTagDescription")}
                        </Popover>
                    </div>
                    
                    <div className="space-x-1">
                        <h4 className="font-semibold inline">
                            {t("Title")}:
                        </h4>
                        <p className="font-medium inline">
                            {socialTags[tag].title || t("TitleNotFound")}
                        </p>
                    </div>

                    <div className="space-x-1">
                        <h4 className="font-semibold inline">
                            {t("Description")}:
                        </h4>
                        <p className="font-medium inline">
                            {socialTags[tag].description || t("DescriptionNotFound")}
                        </p>
                    </div>
                </div>
            ))}
        </section>
    );
}

export default AnalysisSocialTags;