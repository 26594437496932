import PageContainer from "../components/PageContainer";
import AnalyzeForm from "../sections/AnalyzeForm";
import { useTranslation } from "react-i18next";

function AnalyzePage() {
    const { t } = useTranslation();

    return (
        <PageContainer title={t("Analyze")}>
            <section className='max-w-5xl mx-auto'>
                <h1 className='font-medium px-3 py-12 text-3xl sm:text-4xl text-center'>
                    {t('Analyze')}
                </h1>

                <AnalyzeForm/>
            </section>
        </PageContainer>
    );
}

export default AnalyzePage;