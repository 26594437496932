import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import PageContainer from "../../components/PageContainer";

function PrivacyPolicyPage() {
    const { t } = useTranslation();
    const containerRef = useRef();

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.innerHTML = t("PrivacyPolicyBody");
        }
    }, [t]);

    return (
        <PageContainer title={t("PrivacyPolicy")}>
            <section className="container-def py-12 px-6">
                <h1 className="font-medium text-2xl sm:text-3xl">
                    {t("PrivacyPolicy")}
                </h1>
                <div className="policy-body" ref={containerRef}>
                </div>
            </section>
        </PageContainer>
    );
}

export default PrivacyPolicyPage;