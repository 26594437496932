import { Children, useReducer } from "react";
import { useSwipeable } from "react-swipeable";
import { ReactSVG } from "react-svg";
import useSliderHelper from "../hooks/useSliderHelper";
import arrowLeft from "../assets/arrow-left-fill.svg";
import arrowRight from "../assets/arrow-right-fill.svg";

function Slider({ children, repeat = true, startPos = -1 }) {
    const numItems = Children.count(children);
    const { next, prev, initialState, getContainerStyle, getOrder, getSlotStyle, reducer } = useSliderHelper(numItems, startPos);
    const [state, dispatch] = useReducer(reducer, initialState);
    const actualIndex = state.pos === numItems - 1 ? 0 : state.pos + 1;

    const slide = (dir) => {
        const canSlide = repeat || (dir === prev && actualIndex > 0) || (dir === next && actualIndex < numItems - 1);

        if (canSlide) {
            dispatch({ type: dir, numItems });

            setTimeout(() => {
                dispatch({ type: "stopSliding" });
            }, 50);
        }
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => slide(next),
        onSwipedRight: () => slide(prev),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const sliderSlots = Children.map(children, (child, index) => {
        const order = getOrder(index, state.pos);
        const isVisible = repeat || order === 1;

        return (
            <div key={index} style={getSlotStyle(order, isVisible)} data-order={order}>
                {child}
            </div>
        )
    });

    return (
        <div className="overflow-hidden relative" {...handlers}>
            <div className="overflow-visible w-3/4 mx-auto">
                <div style={getContainerStyle(state.dir, state.sliding)}>
                    {sliderSlots}
                </div>
            </div>

            {(repeat || actualIndex > 0) && (
                <button className="absolute left-0 inset-y-0 p-6" onClick={() => slide(prev)}>
                    <ReactSVG src={arrowLeft} className="text-fucsia-def"/>
                </button>
            )}

            {(repeat || actualIndex < numItems - 1) && (
                <button className="absolute right-0 inset-y-0 p-6" onClick={() => slide(next)}>
                    <ReactSVG src={arrowRight} className="text-fucsia-def"/>
                </button>
            )}
        </div>
    );
}

export default Slider;