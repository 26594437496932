import { useTranslation } from "react-i18next";
import Dropdown from "../components/Dropdown";

function LanguageDropdown({ children }) {
    const { i18n } = useTranslation();

    const changeLang = (lang) => {
        localStorage.setItem("lang", lang);
        i18n.changeLanguage(lang);
    }

    return (
        <Dropdown label={children}>
            <ul className="dropdown-list">
                <li><span role="button" onClick={() => changeLang("en")}>EN</span></li>
                <li><span role="button" onClick={() => changeLang("it")}>IT</span></li>
            </ul>
        </Dropdown>
    );
}

export default LanguageDropdown;