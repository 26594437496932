import appSettings from '../resources/app-settings.json';
import RequestClient from './RequestClient';

class ApiClient {
    #appName;
    #apiUrl;
    #currentUser;
    #debugEnabled;
    #rq;
    #wsUrl;

    get appName () {
        return this.#appName;
    }

    get webSocketUrl () {
        return this.#wsUrl;
    }

    constructor () {
        this.#appName = appSettings.appName;
        this.#apiUrl = appSettings.apiURL;
        this.#debugEnabled = appSettings.debug;
        this.#rq = new RequestClient(this.#debugEnabled);
        this.#wsUrl = appSettings.webSocketURL;
    }

    async cancelSubscription (id) {
        const route = `${this.#apiUrl}/user/subscription/${id}`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.delete(route, headers);
    }

    async deleteAnalysis (id) {
        const route = `${this.#apiUrl}/analysis/${id}`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.delete(route, headers);
    }

    async getActivePlans () {
        const route = `${this.#apiUrl}/user/active-plans`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.get(route, headers);
    }
    
    async getOrderHistory () {
        const route = `${this.#apiUrl}/user/order-history`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.get(route, headers);
    }

    async hookDefaultPackage () {
        const route = `${this.#apiUrl}/user/hook-default-package`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };
        const result = await this.#rq.post(route, headers);

        if (!result.success) {
            console.error("Unable to hook default package:", result.message);
            return;
        }

        if (this.#debugEnabled) {
            const addedPkg = result.response?.defaultPkgId;
            console.log("Added package:", addedPkg);
        }
    }

    async indexAnalysis () {
        const route = `${this.#apiUrl}/analysis`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.get(route, headers);
    }

    async indexSubscriptions (sendIdToken) {
        const route = `${this.#apiUrl}/subscriptions`;
        const headers = {};

        if (sendIdToken) {
            headers.idtoken = await this.#getIdToken();
        }
        
        return await this.#rq.get(route, headers);
    }
    
    async indexPackages (sendIdToken) {
        const route = `${this.#apiUrl}/packages`;
        const headers = {};

        if (sendIdToken) {
            headers.idtoken = await this.#getIdToken();
        }

        return await this.#rq.get(route, headers);
    }
    
    openWebSocket () {
        const ws = new WebSocket(this.#wsUrl);
        const uid = this.#currentUser?.uid;

        if (uid) {
            ws.addEventListener("open", () => {
                const message = JSON.stringify({ uid });
                ws.send(message);
            });
        }

        return ws;
    }

    async showAnalysis (id) {
        const route = `${this.#apiUrl}/analysis/${id}`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.get(route, headers);
    }

    async showInvoice (id) {
        const route = `${this.#apiUrl}/user/invoice/${id}`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.get(route, headers);
    }

    async repeatAnalysis (id) {
        const route = `${this.#apiUrl}/analysis/${id}/repeat`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.post(route, headers);
    }
    
    async renameAnalysis (id, name) {
        const route = `${this.#apiUrl}/analysis/${id}`;
        const body = JSON.stringify({ name });
        const idtoken = await this.#getIdToken();
        
        const headers = {
            idtoken,
            "Content-Type": "application/json",
        }
        
        return await this.#rq.patch(route, headers, body);
    }

    async storeAnalysis (body) {
        const route = `${this.#apiUrl}/analysis`;
        const idtoken = await this.#getIdToken();
        const headers = { idtoken };

        return await this.#rq.post(route, headers, body);
    }

    updateUser (user) {
        this.#currentUser = user;

        if (this.#debugEnabled) {
            console.log("Updated user:", user?.uid);
        }
    }

    async #getIdToken () {
        if (this.#currentUser) {
            return await this.#currentUser.getIdToken();
        }

        return null;
    }
}

export default ApiClient;