import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../../context/Context";
import PageContainer from "../../components/PageContainer";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import DialogOpen from "../../components/DialogOpen";
import DeleteAccount from "../../sections/account/DeleteAccount";
import closeIc from "../../assets/x-ic.svg";
import trashIc from "../../assets/trash-ic.svg";

function EditProfilePage() {
    const { authClient, authReady, loggedUser } = useGlobalContext();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [displayNameField, setDisplayNameField] = useState("");
    const [emailField, setEmailField] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const navigate = useNavigate();

    const isFormValid = () => {
        const errors = [];

        if (!displayNameField?.trim()) {
            errors.push("InvalidUsername");
        }

        if (!emailField?.trim()) {
            errors.push("InvalidEmail");
        }

        setErrors(errors);
        return errors.length === 0;
    }

    const onDisplayNameChange = (event) => {
        setDisplayNameField(event.target.value);
    }

    const onEmailChange = (event) => {
        setEmailField(event.target.value);
    }

    const toggleDeleteDialog = () => {
        setShowDelete(prev => !prev);
    }

    const submitHandler = (event) => {
        event.preventDefault();

        if (!loggedUser) {
            setErrors(["AuthRequired"]);
            return;
        }

        if (!loading && isFormValid()) {
            setLoading(true);

            authClient.updateCurrentProfile(displayNameField, emailField).then(result => {
                setLoading(false);

                if (!result.success) {
                    setErrors([result.message]);
                    return;
                }

                const message = result.emailUpdated ? "VerifyNewEmail" : "ProfileUpdated";

                navigate("/account", {
                    state: { message },
                });
            });
        }
    }

    useEffect(() => {
        if (authReady && loggedUser) {
            setDisplayNameField(loggedUser.displayName);
            setEmailField(loggedUser.email);
        }
    }, [authReady, loggedUser, navigate]);

    return (
        <PageContainer title={t("Profile")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row sm:gap-12'>
                    <div className="basis-14">
                        <Link to="/account">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                    </div>

                    <div className="basis-1/2 flex flex-col gap-6">
                        <h1 className="font-bold sm:mb-6 text-2xl sm:text-4xl">
                            {t('Profile')}
                        </h1>

                        {errors.length > 0 && (
                            <ul className="text-red-def">
                                {errors.map((err, index) => (
                                    <li key={index}>{t(err)}</li>
                                ))}
                            </ul>
                        )}

                        <form className="contact-form text-sm sm:text-base" onSubmit={submitHandler}>
                            <label>
                                <p>{t('Username')}</p>
                                <input type="text" name="displayName" value={displayNameField} onChange={onDisplayNameChange} required/>
                            </label>
                            <label>
                                <p>{t('EMailAddress')}</p>
                                <input type="email" name="email" value={emailField} onChange={onEmailChange} required/>
                            </label>

                            <div className="contact-form-options">
                                <button type="submit" className='btn btn-primary rounded-lg' disabled={loading}>
                                    <span className='block font-bold p-1'>
                                        {t('SaveProfile')}
                                    </span>
                                </button>
                                <Link to="/account">
                                    {t('Cancel')}
                                </Link>
                                <button type="button" onClick={toggleDeleteDialog} className="btn ms-auto text-red-def">
                                    <div className="flex items-end gap-2">
                                        <ReactSVG src={trashIc}/>
                                        <span className="font-medium">
                                            {t('DeleteAccount')}
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {showDelete && (
                <DialogOpen>
                    <button type="button" onClick={toggleDeleteDialog} className="dialog-close-btn">
                        <ReactSVG src={closeIc} aria-label="Close"/>
                    </button>
                    <div className="dialog-content">
                        <DeleteAccount onCancel={toggleDeleteDialog}/>
                    </div>
                </DialogOpen>
            )}
        </PageContainer>
    );
}

export default EditProfilePage;