import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import useAnalysisTools from "../../hooks/useAnalysisTools";
import Popover from "../../components/Popover";
import GaugeChart from "../../components/GaugeChart";
import TextFormatter from "../../components/TextFormatter";
import pencilIc from "../../assets/mdi-light_pencil.svg";
import contentAnalysisIc from "../../assets/content-analysis-ic.svg";

function AnalysisContent({ name, wordCount, textAnalysis, keywords, rating, siteOptions, showRename }) {
    const { t } = useTranslation();
    const { isWordCountGood, parseKeywords, parseRating } = useAnalysisTools();
    const parsedRating = parseRating(rating);
    const parsedWordCount = parseInt(wordCount) || 0;

    const keywordsFound = useMemo(() => {
        return parseKeywords(keywords?.keywordAnalysis);
    }, [keywords, parseKeywords]);

    const keywordsSuggested = useMemo(() => {
        return parseKeywords(keywords?.keywordResearch);
    }, [keywords, parseKeywords]);

    const editBtnClickHandler = () => {
        if (typeof showRename === "function") {
            showRename();
        }
    }

    return (
        <section className="flex flex-col sm:flex-row gap-6 sm:items-start" id="contentAnalysis">
            <div className="analysis-box flex flex-col sm:w-1/3 py-4 px-6">
                <div className="flex flex-wrap gap-2 mb-4">
                    <h2 className="text-xl font-bold mb-1">
                        {name}
                    </h2>
                    <button onClick={editBtnClickHandler}>
                        <ReactSVG src={pencilIc} title="Edit" className="relative bottom-1"/>
                    </button>
                </div>
                <div className="max-w-56 mx-auto w-full">
                    <GaugeChart rating={parsedRating}>
                        <p className="absolute bottom-0 font-bold inset-x-0 mb-1 text-3xl text-fucsia-def">
                            {parsedRating}
                        </p>
                    </GaugeChart>
                </div>
                <div className="pt-4 space-y-1">
                    <div>
                        <h3 className="font-semibold">
                            {t("AnalyzedPage")}:
                        </h3>
                        <p className="break-words text-sm">
                            {siteOptions.targetPage}
                        </p>
                    </div>
                    <div>
                        <h3 className="font-semibold">
                            {t("ProvidedTypology")}
                        </h3>
                        <p className="break-words text-sm">
                            {siteOptions.typology}
                        </p>
                    </div>

                    {siteOptions.target && (
                        <div>
                            <h3 className="font-semibold">
                                {t("ProvidedTarget")}
                            </h3>
                            <p className="break-words text-sm">
                                {siteOptions.target}
                            </p>
                        </div>
                    )}

                    <div>
                        <h3 className="font-semibold">
                            {t("ProvidedLanguange")}
                        </h3>
                        <p className="break-words text-sm">
                            {siteOptions.language}
                        </p>
                    </div>
                </div>
            </div>
            <div className="analysis-box flex-1 py-4 px-6 space-y-4">
                <div className="analysis-box-title">
                    <ReactSVG src={contentAnalysisIc}/>
                    <h2 className="font-semibold text-2xl">
                        {t("ContentAnalysis")}
                    </h2>
                </div>
                <div>
                    <p>
                        <TextFormatter text={textAnalysis}/>
                    </p>
                </div>
                <div>
                    <div className="analysis-result-title">
                        <h3 className="mb-1 text-xl font-semibold">
                            {t("WordCountTitle")}
                        </h3>
                        <Popover>
                            {t("WordCountDescription")}
                        </Popover>
                    </div>
                    <p>
                        {(isWordCountGood(parsedWordCount) ? (t("WordCountGood")) : t("WordCountInsuff"))}
                        <br/>
                        <strong className="font-semibold">
                            {t("WordCountText").replace("%count%", wordCount)}
                        </strong>
                    </p>
                </div>
                <div>
                    <div className="analysis-result-title">
                        <h3 className="mb-1 text-xl font-semibold">
                            {t("Keywords")}
                        </h3>
                        <Popover>
                            {t("KeywordsDescription")}
                        </Popover>
                    </div>
                    <p>
                        {t("KeywordsFoundIntro")} <strong className="font-semibold">{keywordsFound}</strong>.
                        <br/>
                        {t("KeywordsSuggestedIntro")} <strong className="font-semibold">{keywordsSuggested}</strong>.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default AnalysisContent;