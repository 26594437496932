import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import scoreIC from "../assets/scrore-ic.svg";
import optimizedContentIc from "../assets/optimized-content-ic.svg";
import reportIc from "../assets/report-ic.svg";
import suggestionIC from "../assets/suggestion-ic.svg";
import contentAnalysisDeskImg from "../assets/AnalisiContenutoDesk.png";
import scoreDeskImg from "../assets/PunteggioDesktop.png";
import seoAnalysisDeskImg from "../assets/AnalisiSEODesk.png";
import optimizedContentDeskImg from "../assets/ContenutoOttimizzatoDesk.png";
import contentAnalysisImg from "../assets/AnalisiContenuto.png";
import scoreImg from "../assets/Punteggio.png";
import seoAnalysisImg from "../assets/AnalisiSEO.png";
import optimizedContentImg from "../assets/ContenutoOttimizzato.png";

function FeaturesBanner() {
    const { t } = useTranslation();

    return (
        <section className='py-12 overflow-hidden'>
            <div className='container-def px-6'>
                <h2 className='font-bold text-3xl sm:text-4xl pt-6'>
                    {t("Features")}
                </h2>

                {/* SM/MD SCREENS */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-12 lg:hidden pt-12">
                    <div className="space-y-2">
                        <figure>
                            <img src={scoreImg} alt="Score"/>
                        </figure>
                        <div className="px-2 space-y-2">
                            <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                <ReactSVG src={scoreIC}/>
                                <span>{t("Score")}</span>
                            </h3>
                            <p>{t("ScoreCaption")}</p>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <figure>
                            <img src={seoAnalysisImg} alt="SEO Analysis"/>
                        </figure>
                        <div className="px-2 space-y-2">
                            <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                <ReactSVG src={reportIc}/>
                                <span>{t("SeoAnalysis")}</span>
                            </h3>
                            <p>{t("SeoAnalysisCaption")}</p>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <figure>
                            <img src={optimizedContentImg} alt="OptimizedContent"/>
                        </figure>
                        <div className="px-2 space-y-2">
                            <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                <ReactSVG src={optimizedContentIc}/>
                                <span>{t("OptimizedContent")}</span>
                            </h3>
                            <p>{t("OptimizedContentCaption")}</p>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <figure>
                            <img src={contentAnalysisImg} alt="Advice"/>
                        </figure>
                        <div className="px-2 space-y-2">
                            <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                <ReactSVG src={suggestionIC}/>
                                <span>{t("ContentAnalysis")}</span>
                            </h3>
                            <p>{t("AdvicesCaption")}</p>
                        </div>
                    </div>
                </div>

                {/* LG SCREENS */}
                <Tabs className="hidden lg:flex flex-row gap-12">
                    <div className='basis-1/3 relative z-10'>
                        <TabList className="mt-12 flex flex-col gap-3">
                            <Tab className="tab-box tab-box-ligthblue">
                                <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                    <ReactSVG src={scoreIC}/>
                                    <span>{t("Score")}</span>
                                </h3>
                                <p className="pt-2 text-sm">
                                    {t("ScoreCaption")}
                                </p>
                            </Tab>
                            <Tab className="tab-box tab-box-blue">
                                <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                    <ReactSVG src={reportIc}/>
                                    <span>{t("SeoAnalysis")}</span>
                                </h3>
                                <p className="pt-2 text-sm">
                                    {t("SeoAnalysisCaption")}
                                </p>
                            </Tab>
                            <Tab className='tab-box tab-box-pink'>
                                <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                    <ReactSVG src={optimizedContentIc}/>
                                    <span>{t("OptimizedContent")}</span>
                                </h3>
                                <p className="pt-2 text-sm">
                                    {t("OptimizedContentCaption")}
                                </p>
                            </Tab>
                            <Tab className='tab-box tab-box-fucsia'>
                                <h3 className='flex font-semibold gap-2 items-center text-2xl'>
                                    <ReactSVG src={suggestionIC}/>
                                    <span>{t("ContentAnalysis")}</span>
                                </h3>
                                <p className="pt-2 text-sm">
                                    {t("AdvicesCaption")}
                                </p>
                            </Tab>
                        </TabList>
                    </div>

                    <div className="flex-1 mt-12 relative">
                        <TabPanel>
                            <div className="feature-banner-image">
                                <img src={scoreDeskImg} alt="Score"/>
                            </div>
                        </TabPanel>
                        
                        <TabPanel>
                            <div className="feature-banner-image">
                                <img src={seoAnalysisDeskImg} alt="Seo Analysis"/>
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className="feature-banner-image">
                                <img src={optimizedContentDeskImg} alt="Optimized content"/>
                            </div>
                        </TabPanel>
                            
                        <TabPanel>
                            <div className="feature-banner-image">
                                <img src={contentAnalysisDeskImg} alt="Content analysis"/>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </section>
    );
}

export default FeaturesBanner;