import analysisParams from "../resources/analysis-params.json";

function useGoogleSnippetAnalyzer() {
    const minTitleLen = analysisParams.googleSnippet.minTitleLen;
    const maxTitleLen = analysisParams.googleSnippet.maxTitleLen;
    const minMetaDescrLen = analysisParams.googleSnippet.minMetaDescriptionLen;
    const maxMetaDescrLen = analysisParams.googleSnippet.maxMetaDescriptionLen;
    const maxUrlLen = analysisParams.googleSnippet.maxUrlLen;

    const checkTitle = (title) => {
        return title && title.length > minTitleLen && title.length <= maxTitleLen;
    }

    const checkMetaDescription = (mDescr) => {
        return mDescr && mDescr.length > minMetaDescrLen && mDescr.length <= maxMetaDescrLen;
    }

    const checkUrl = (url) => {
        return url && url.length < maxUrlLen;
    }

    return {
        minTitleLen,
        maxTitleLen,
        minMetaDescrLen,
        maxMetaDescrLen,
        maxUrlLen,
        checkTitle,
        checkMetaDescription,
        checkUrl,
    }
}

export default useGoogleSnippetAnalyzer;