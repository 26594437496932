import { useEffect } from "react";

function DialogOpen({ children }) {
    useEffect(() => {
        document.body.classList.add("dialog-open");

        return () => {
            document.body.classList.remove("dialog-open");
        }
    }, []);

    return (
        <dialog className="dialog" open>
            <div className="m-3 relative">
                {children}
            </div>
        </dialog>
    )
}

export default DialogOpen;