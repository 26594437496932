import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import logo from '../assets/logo.svg';
import emIcon from '../assets/mail.svg';
import inIcon from '../assets/linkedin.svg';
import discordIc from '../assets/discord.svg';
import navLinks from '../resources/nav-links.json';

function Footer() {
    const { t } = useTranslation();

    return (
        <footer className='ws-footer'>
            <div className='container-def px-6 pt-12 pb-8'>    
                <div className='flex flex-col sm:flex-row sm:pb-6 gap-x-6 gap-y-12 items-center'>
                    <div className='flex-1'>
                        <ReactSVG src={logo} aria-label='Origo' className='w-28'/>
                    </div>

                    <nav className='flex flex-1 justify-center text-center'>
                        <ul className='flex flex-col sm:flex-row gap-6'>
                            {navLinks.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.path}>
                                        {t(item.label)}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <div className='flex flex-1 justify-end items-end gap-3'>
                        <a href='https://discord.gg/qaRmrDepE7' target='_blank' rel='noreferrer'>
                            <ReactSVG src={discordIc} aria-label='Discord'/>
                        </a>
                        <a href='https://linkedin.com/in/origo-ai' target='_blank' rel='noreferrer'>
                            <ReactSVG src={inIcon} aria-label='Linkedin'/>
                        </a>
                        <a href='mailto:info@origoai.cloud' rel='noreferrer'>
                            <ReactSVG src={emIcon} aria-label='E-Mail'/>
                        </a>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row items-center pt-6 gap-y-6 text-sm'>
                    <p className='flex-1'>
                        Origo 2024
                    </p>
                    <nav className='flex flex-wrap gap-3 justify-center'>
                        <Link to='/terms-conditions'>{t('TermsConditions')}</Link>
                        <Link to='/cookie-declaration'>{t('CookieDeclaration')}</Link>
                        <Link to='/privacy-policy'>{t('Privacy policy')}</Link>
                    </nav>
                </div>
            </div>
        </footer>
    );
};

export default Footer;