import { useState } from "react";
import { ReactSVG } from "react-svg";
import arrowNextIc from '../assets/next-arrow-ic.svg';

function CollapseBox({ label, children }) {
    const [show, setShow] = useState(false);

    const btnIconStyles = {
        transform: show ? "rotate(270deg)" : "rotate(90deg)",
    }

    const containerStyles = {
        boxShadow: show ? "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)" : "0 0 #0000",
    }

    const toggle = () => {
        setShow(prev => !prev);
    }

    return (
        <div className="mt-2 relative rounded-xl overflow-hidden" style={containerStyles}>
            <button onClick={toggle} className="banner-fucsia flex flex-row items-center justify-between px-4 py-4 text-start w-full">
                <span className="font-medium">
                    {label}
                </span>
                <ReactSVG src={arrowNextIc} title="Show" className="transition-transform px-1" style={btnIconStyles}/>
            </button>

            {show && (
                <div className="p-3 bg-white text-black">
                    {children}
                </div>
            )}
        </div>
    );
}

export default CollapseBox;