import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../context/Context";
import { ReactSVG } from "react-svg";
import DialogOpen from "../../components/DialogOpen";
import closeIc from "../../assets/x-ic.svg";

function DeleteAccount({ onCancel }) {
    const { apiClient, authClient, currentProviders } = useGlobalContext();
    const { t } = useTranslation();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [showSubDialog, setShowSubDialog] = useState(false);
    const navigate = useNavigate();

    const isPasswordAccount = useMemo(() => {
        return currentProviders.includes("password");
    }, [currentProviders]);

    const closeSubDialog = () => {
        setShowSubDialog(false);
    }

    const onDeleteResult = (result) => {
        setLoading(false);

        if (!result.success) {
            setError(result.message);
            return;
        }

        navigate("/", {
            state: {
                message: "AccountDeleted",
                showAlert: true
            }
        });
    }

    const cancel = () => {
        if (typeof onCancel === "function") {
            onCancel();
        }
    }

    const submitHandler = (event) => {
        const formData = new FormData(event.target);
        const fields = Object.fromEntries(formData.entries());
        event.preventDefault();

        if (fields.confirmDelete !== "true") {
            setError("ConfirmAccountDeletion");
            return;
        }

        if (!loading) {
            setLoading(true);

            apiClient.getActivePlans().then(result => {
                if (!result.success || !result.response) {
                    setError(result.message);
                    return;
                }
                
                const activeSubs = result.response.subscriptions;

                if (Array.isArray(activeSubs) && activeSubs.some(s => !s.deletedAt)) {
                    setShowSubDialog(true);
                    setLoading(false);
                    return;
                }
    
                if (isPasswordAccount) {
                    authClient.deleteAccount(fields.email, fields.password).then(onDeleteResult);
                    return;
                }
                
                if (currentProviders.includes("google.com")) {
                    authClient.deleteAccountWithGoogle().then(onDeleteResult);
                    return;
                }
    
                setLoading(false);
                console.error("No valid provider in:", currentProviders);
            });
        }
    }

    return (
        <div className="px-6 py-3">
            <h2 className="font-semibold text-2xl text-center mb-3 py-3">
                {t("DeleteAccount")}
            </h2>

            {error && (
                <p className="text-red-500 mb-3">
                    {t(error)}
                </p>
            )}

            <form className="contact-form max-w-sm sm:max-w-full mx-auto sm:mx-0 text-sm sm:text-base" onSubmit={submitHandler}>
                {isPasswordAccount && (
                    <>
                        <label>
                            <span>{t('Email')}</span>
                            <input type="email" name="email" required/>
                        </label>
                        <label>
                            <span>{t('CurrentPassword')}</span>
                            <input type="password" name="password" required/>
                        </label>
                    </>
                )}
                
                <p className="text-sm">
                    {t('DeleteAccountWarning')}
                </p>
                <label>
                    <div className="flex flex-row gap-2">
                        <input
                            type="checkbox"
                            name="confirmDelete"
                            value="true"
                            className="checkbox-input"
                            required
                        />
                        <span className="cursor-pointer">
                            {t('ConfirmAccountDeletion')}
                        </span>
                    </div>
                </label>
                <div className="flex flex-wrap items-center pt-6 pb-2 gap-6 justify-end border-t">
                    <button type="button" onClick={cancel}>{t("Cancel")}</button>
                    <button type="submit" className="btn bg-red-800 text-white rounded-lg" disabled={loading}>
                        <span className="block font-bold m-1">
                            {t('DeleteAccount')}
                        </span>
                    </button>
                </div>
            </form>

            {showSubDialog && (
                <DialogOpen>
                    <button type="button" onClick={closeSubDialog} className="dialog-close-btn">
                        <ReactSVG src={closeIc} aria-label="Close"/>
                    </button>
                    <div className="dialog-body max-w-lg text-center w-screen">
                        <h2 className="font-medium py-3 px-6 text-lg">
                            {t("ActiveSubscription")}
                        </h2>
                        <p>{t("AccountDeleteSubDialog")}</p>
                    </div>
                    <div className="dialog-options border-t text-sm">
                        <button type="button" onClick={closeSubDialog}>
                            {t("Cancel")}
                        </button>
                        <Link to="/account/change-plan" className="btn bg-red-800 text-white">
                            <span className="block font-semibold py-1">
                                {t("Confirm")}
                            </span>
                        </Link>
                    </div>
                </DialogOpen>
            )}
        </div>
    );
}

export default DeleteAccount;