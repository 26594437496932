import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../../context/Context";
import PageContainer from "../../components/PageContainer";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import LoadingSkeleton from "../../components/LoadingSkeleton";

function OrderHistoryPage() {
    const { apiClient, authReady, getDateString } = useGlobalContext();
    const { t } = useTranslation();
    const [history, setHistory] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const getInvoice = (invoiceId) => {
        setLoading(true);

        apiClient.showInvoice(invoiceId).then(result => {
            setLoading(false);

            if (!result.success) {
                setError(result.message);
                return;
            }

            if (result.response?.invoiceUrl) {
                window.open(result.response.invoiceUrl);
            }
        })
    }

    const parseTimeStamp = useCallback((ts) => {
        if (!ts) {
            return null;
        }

        return new Date(ts._seconds * 1000 + ts._nanoseconds / 1000000).getTime();
    }, []);

    useEffect(() => {
        if (authReady) {
            setLoading(true);

            apiClient.getOrderHistory().then(result => {
                setLoading(false);

                if (!result.success) {
                    setError(result.message);
                    return;
                }

                const orders = result.response?.orders;

                if (Array.isArray(orders)) {
                    orders.sort((a, b) => parseTimeStamp(b.createdAt) - parseTimeStamp(a.createdAt));
                }

                setHistory(orders);
            });
        }
    }, [apiClient, authReady, parseTimeStamp]);

    return (
        <PageContainer title={t("OrderHistory")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row sm:gap-12 relative'>
                    <div className="basis-14">
                        <Link to="/account">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                    </div>
                    <div className="flex flex-1 flex-col gap-6">
                        <h1 className="font-bold sm:mb-6 text-2xl sm:text-4xl">
                            {t('BillingHistory')}
                        </h1>

                        {error && (
                            <p className="text-red-def">
                                {t(error)}
                            </p>
                        )}

                        {history.length === 0 && (
                            <p className="font-medium">
                                {loading ? <LoadingSkeleton/> : t("EmptyOrders")}
                            </p>
                        )}

                        {history.length > 0 && (
                            <div className="space-y-6" aria-disabled={loading}>
                                <div className="overflow-x-auto">
                                    <table className="order-history-table text-xs sm:text-sm">
                                        <thead>
                                            <tr>
                                                <th>{t('IssuingDate')}</th>
                                                <th>{t('Description')}</th>
                                                <th>{t('Amount')}</th>
                                                <th>{t('Invoice')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {history.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{getDateString(parseTimeStamp(item.createdAt))}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.price}</td>
                                                    <td>
                                                        <button type="button" onClick={() => getInvoice(item.invoice)} className="underline" disabled={loading}>
                                                            {t("Details")}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </PageContainer>
    );
}

export default OrderHistoryPage;