import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/Context';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import PageContainer from '../components/PageContainer';
import PricingBox from '../components/PricingBox';
import BoxWrapper from '../components/BoxWrapper';
import pricingBg from '../assets/pricing-bg.svg';

function PricingPage() {
    const { apiClient, loggedUser, switchModal } = useGlobalContext();
    const { t } = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const [subscriptions, setSubscriptions] = useState([]);
    const [packages, setPackages] = useState([]);

    const buyPlan = (item) => {
        if (!loggedUser) {
            switchModal("login");
            return;
        }

        if (item.paymentUrl) {
            document.location.href = item.paymentUrl;
        }
    }

    useEffect(() => {
        const sendIdToken = loggedUser ? true : false;

        apiClient.indexSubscriptions(sendIdToken).then(result => {
            const docs = result.response?.docs;

            if (Array.isArray(docs)) {
                const subs = docs.filter(item => item.data?.paymentUrl);
                subs.sort((a, b) => a.data.tokens - b.data.tokens);
                setSubscriptions(subs);
            }
        });
        
        apiClient.indexPackages(sendIdToken).then(result => {
            const docs = result.response?.docs;

            if (Array.isArray(docs)) {
                const pkgs = docs.filter(item => item.data?.paymentUrl);
                pkgs.sort((a, b) => a.data.tokens - b.data.tokens);
                setPackages(pkgs);
            }
        });
    }, [apiClient, loggedUser]);

    return (
        <PageContainer title={t("Pricing")}>
            <section className='container-def min-h-screen pb-12'>
                <div className='font-medium py-12 px-3 text-center'>
                    <h1 className='mb-3 font-semibold text-3xl sm:text-4xl'>
                        {tabIndex === 0 ? t('PricingTitle') : t('PackagesTitle')}
                    </h1>
                    <h2 className='text-lg sm:text-xl'>
                        {tabIndex === 0 ? t('PricingSubtitle') : t('PackagesSubtitle')}
                    </h2>
                </div>

                <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
                    <div className='flex justify-center pb-16 md:pb-20 px-6'>
                        <TabList className='rounded-tab-list'>
                            <Tab>{t("Subscriptions")}</Tab>
                            <Tab>{t("Packages")}</Tab>
                        </TabList>
                    </div>

                    <TabPanel>
                        <BoxWrapper sliderRepeat={true} sliderStartPos={0}>
                            {subscriptions.map((sub, index) => (
                                <PricingBox
                                    key={index}
                                    name={t(sub.data.name)}
                                    price={`${sub.data.price}/${t('month')}`}
                                    credits={sub.data.tokens}
                                    description={t(sub.data.description)}
                                    onChoose={() => buyPlan(sub)}
                                />
                            ))}
                        </BoxWrapper>
                    </TabPanel>

                    <TabPanel>
                        <BoxWrapper sliderRepeat={true} sliderStartPos={0}>
                            {packages.map((pkg, index) => (
                                <PricingBox
                                    key={index}
                                    name={pkg.data.name}
                                    price={pkg.data.price}
                                    onChoose={() => buyPlan(pkg)}
                                    credits={pkg.data.tokens}
                                    iconCount={index + 1}
                                />
                            ))}
                        </BoxWrapper>

                        <div className='max-w-3xl mx-auto px-6 pt-12'>
                            <p className='text-center'>
                                {t('CreditPacketCaption')}
                            </p>
                        </div>
                    </TabPanel>
                </Tabs>

                {subscriptions.length === 0 && packages.length === 0 && (
                    <BoxWrapper>
                        {[...Array(3)].map((_, index) => (
                            <PricingBox key={index}/>
                        ))}
                    </BoxWrapper>
                )}
            </section>

            <div className='bg-no-repeat bg-cover bg-top h-36 lg:h-80' style={{backgroundImage: `url(${pricingBg})`}}></div>
        </PageContainer>
    );
};

export default PricingPage;