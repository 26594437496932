import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../context/Context";
import Dropdown from "../components/Dropdown";

function UserDropdown({ isAccountPage, children }) {
    const { authClient, switchModal, loggedUser } = useGlobalContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const logout = () => {
        authClient.logout().then(result => {
            if (!result.success) {
                console.error("Logout failed:", result.error?.code);
                return;
            }

            navigate("/");
        });
    }

    const showLogin = () => {
        switchModal("login");
    }

    const showRegister = () => {
        switchModal("register");
    }

    return (
        <Dropdown label={children} isActive={isAccountPage}>
            <ul className='dropdown-list'>
                { loggedUser ? (
                    <>
                        <li><NavLink to="/account">{t('Account')}</NavLink></li>
                        <li><NavLink to="/account/analysis">{t('Analysis')}</NavLink></li>
                        <li><span role="button" onClick={logout}>{t('Logout')}</span></li>
                    </>
                ) : (
                    <>
                        <li><span role="button" onClick={showLogin}>{t('Login')}</span></li>
                        <li><span role="button" onClick={showRegister}>{t('Register')}</span></li>
                    </>
                )}
            </ul>
        </Dropdown>
    );
}

export default UserDropdown;