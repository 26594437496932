import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../context/Context";
import googleIcon from "../assets/google-icon.svg";

function GoogleSingInButton ({ disabled, onAuthResult }) {
    const { authClient } = useGlobalContext();
    const { t } = useTranslation();
    const [pending, setPending] = useState(false);

    const signIn = () => {
        setPending(true);

        authClient.loginWithGoogle().then(result => {
            if (typeof onAuthResult === "function") {
                onAuthResult(result);
            }

            setPending(false);
        });
    }

    return (
        <button type='button' onClick={signIn} disabled={disabled || pending} className='bg-gray-200 btn font-bold min-h-14 text-black rounded-xl'>
            <span className='flex flex-row gap-3 justify-center items-center text-lg'>
                <ReactSVG src={googleIcon} />
                <span className='text-base'>
                    {t('LoginGoogle')}
                </span>
            </span>
        </button>
    )
}

export default GoogleSingInButton;