import Slider from './Slider';

function BoxWrapper({ children, sliderRepeat = false, sliderStartPos = -1 }) {
    return (
        <div className='relative'>
            <div className='hidden md:flex flex-row flex-wrap gap-12 px-6'>
                {children}
            </div>

            <div className='md:hidden'>
                <Slider repeat={sliderRepeat} startPos={sliderStartPos}>
                    {children}
                </Slider>
            </div>
        </div>
    );
};

export default BoxWrapper;