import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../../context/Context";
import PageContainer from "../../components/PageContainer"
import AnalyzeForm from "../../sections/AnalyzeForm";
import DialogOpen from "../../components/DialogOpen";
import AnalysisListItem from "../../sections/analysis/AnalysisListItem";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import plusIc from "../../assets/fluent_add-20-filled.svg";
import closeIc from "../../assets/x-ic.svg";
import backBtnIcon from "../../assets/back-arrow-ic.svg";
import AnalysisFilterForm from "../../sections/analysis/AnalysisFilterForm";

function AnalysisListPage() {
    const { apiClient, authReady } = useGlobalContext();
    const { t } = useTranslation();
    const [analysisList, setAnalysisList] = useState([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [showAnalyze, setShowAnalyze] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [filterType, setFilterType] = useState("");

    const displayList = useMemo(() => {
        const query = searchInput.toLowerCase().trim();

        const filtered = analysisList.filter(doc => {
            const id = doc.id;
            const name = doc.data?.name;
            const url = doc.data?.url;
            
            if (id && id.toLowerCase().includes(query)) {
                return true;
            }
            
            if (name && name.toLowerCase().includes(query)) {
                return true;
            }

            if (url && url.toLowerCase().includes(query)) {
                return true;
            }
            
            return false;
        });

        switch (filterType) {
            case "url":
                return filtered.filter(doc => doc.data?.url);
            case "html":
                return filtered.filter(doc => !doc.data?.url);
            default:
                return filtered;
        }
    }, [analysisList, searchInput, filterType]);

    const fetchList = useCallback(() => {
        setLoading(true);

        apiClient.indexAnalysis().then(result => {
            setLoading(false);

            if (!result.success || !result.response) {
                setError(result.message);
                return;
            }

            const analysis = result.response.docs;
            setAnalysisList(analysis);
            setError(null);
            sessionStorage.setItem("analysisList", JSON.stringify(analysis));
        });
    }, [apiClient]);

    const deleteAnalysis = (id) => {
        if (!loading) {
            setLoading(true);

            apiClient.deleteAnalysis(id).then(result => {
                setLoading(false);

                if (!result.success) {
                    setError(result.message);
                    return;
                }

                fetchList();
            });
        }
    }

    const renameAnalysis = (id, newName) => {
        if (!loading) {
            setLoading(true);

            apiClient.renameAnalysis(id, newName).then(result => {
                setLoading(false);

                if (!result.success) {
                    setError(result.message);
                    return;
                }

                fetchList();
            });
        }
    }

    const toggleAnalyzeDialog = () => {
        setShowAnalyze(prev => !prev);
    }

    useEffect(() => {
        const sessionAnalysis = JSON.parse(sessionStorage.getItem("analysisList"));

        if (sessionAnalysis && Array.isArray(sessionAnalysis)) {
            setAnalysisList(sessionAnalysis);
        }

        if (authReady) {
            fetchList();
        }
    }, [authReady, fetchList]);

    return (
        <PageContainer title={t("YourAnalysis")}>
            <section className='container-def py-12'>
                <div className="px-6">
                    <div className="flex flex-col sm:flex-row sm:gap-12 mb-12">
                        <Link to="/account" className="basis-14">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                        <div className="flex flex-1 flex-wrap gap-6 sm:gap-12">
                            <div className="flex-1">
                                <h1 className="font-bold text-2xl sm:text-4xl">
                                    {t("YourAnalysis")}
                                </h1>
                            </div>

                            <button type="button" onClick={toggleAnalyzeDialog} className="btn btn-fucsia ms-auto place-self-center rounded-lg">
                                <div className="flex flex-row items-center gap-2">
                                    <span className="hidden sm:inline-block p-1 font-medium">
                                        {t("NewAnalysis")}
                                    </span>
                                    <ReactSVG src={plusIc} aria-label="Analyze"/>
                                </div>
                            </button>
                        </div>
                    </div>

                    {error && (
                        <p className="mb-6 font-medium">
                            {t(error)}
                        </p>
                    )}

                    <div aria-disabled={loading}>
                        <div className="mb-6">
                            <AnalysisFilterForm
                                setFilter={setFilterType}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                            />
                        </div>

                        <ul className="space-y-6">
                            {displayList.map((doc, index) => (
                                <AnalysisListItem key={index} doc={doc} onDelete={deleteAnalysis} onRename={renameAnalysis}/>
                            ))}
                        </ul>
                    </div>

                    {analysisList.length === 0 && (
                        <>
                            {loading ? (
                                <div className="analysis-list-item px-6 py-3 relative">
                                    <div className="flex flex-col sm:flex-row justify-between gap-2">
                                        <div className="flex-1 max-w-xl pt-1">
                                            <h2 className="font-semibold text-2xl">
                                                <LoadingSkeleton/>
                                            </h2>
                                            <div className="mt-2">
                                                <LoadingSkeleton count={2}/>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-3 pt-3 pb-6">
                                            <div className="analysis-list-item-rating m-auto w-32 sm:w-20"></div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div className="basis-8">
                                            <LoadingSkeleton/>
                                        </div>
                                        <div className="basis-28">
                                            <LoadingSkeleton/>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="py-12 font-medium max-w-xl mx-auto text-center">
                                    <p className="mb-8">
                                        {t("AnalysisEmpty")}
                                    </p>
                                    <button type="button" onClick={toggleAnalyzeDialog} className="btn btn-primary rounded-lg">
                                        <span className='block font-semibold p-2 sm:px-3 text-lg sm:text-xl'>
                                            {t("StartNow")}
                                        </span>
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>

            {showAnalyze && (
                <DialogOpen>
                    <button type="button" onClick={toggleAnalyzeDialog} className="dialog-close-btn">
                        <ReactSVG src={closeIc} aria-label="Close"/>
                    </button>
                    <div className="dialog-analyze">
                        <AnalyzeForm close={toggleAnalyzeDialog}/>
                    </div>
                </DialogOpen>
            )}
        </PageContainer>
    )
}

export default AnalysisListPage