import { useState } from 'react';
import { useGlobalContext } from '../../context/Context';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import closeIc from '../../assets/x-ic.svg';
import emailIcon from '../../assets/ic_outline-email.svg';
import xRedIcon from "../../assets/x-circle-red.svg";

function ForgotPassModal() {
    const { authClient, switchModal } = useGlobalContext();
    const { t } = useTranslation();
    const [error, setError] = useState();
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendReset = (email) => {
        setLoading(true);

        authClient.resetPasswordEmail(email).then(result => {
            setLoading(false);

            if (!result.success) {
                setError(result.message);
                return;
            }

            setEmailSent(true);
        });
    }

    const submitHandler = (event) => {
        const formData = new FormData(event.target);
        const email = formData.get("email");
        
        event.preventDefault();

        if (emailSent) {
            switchModal();
            return;
        }
        
        if (!email) {
            setError("InvalidEmail");
            return;
        }
        
        sendReset(email.trim());
    }

    return (
        <div className='fixed bg-black/75 inset-0 items-center z-50'>
            <div className='modal'>
                <button className='dialog-close-btn' onClick={() => switchModal()}>
                    <ReactSVG src={closeIc} title='Close'/>
                </button>

                <h2 className='font-bold pt-6 pb-4 text-2xl text-center'>
                    {t('ForgotPassword')}
                </h2>

                <form className='auth-form' onSubmit={submitHandler}>
                    {emailSent ? (
                        <div className='mt-6 pb-3 text-center'>
                            <ReactSVG src={emailIcon} className='inline-block'/>
                            <p className='text-sm'>
                                {t('PasswordResetEmailSent')}
                            </p>
                        </div>
                    ) : (
                        <div className='mt-6'>
                            <p className='text-center text-sm'>
                                {t('PasswordRecoveryInfo')}
                            </p>

                            {error && (
                                <span className='auth-form-error'>
                                    <ReactSVG src={xRedIcon}/>
                                    {t(error)}
                                </span>
                            )}

                            <label className='auth-form-field'>
                                <span className='auth-form-placeholder'>
                                    {t('Email')}
                                </span>
                                <input type='email' name='email' required />
                            </label>
                        </div>
                    )}

                    <div className='auth-form-options'>
                        <button type='submit' disabled={loading} className='btn btn-fucsia font-bold min-h-14 text-white rounded-xl'>
                            <span className='block text-base'>
                                {emailSent ? t('Confirm') : t('SendResetInstructions')}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassModal;