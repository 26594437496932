import { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import DialogOpen from '../components/DialogOpen';
import AnalyzeForm from '../sections/AnalyzeForm';
import FeaturesBanner from '../sections/FeaturesBanner';
import insertSiteIC from '../assets/insertSiteIC.svg';
import answareIC from '../assets/answereIc.svg';
import analysisIC from '../assets/analysisIC.svg';
import closeIc from '../assets/x-ic.svg';
import homeBannerBG from '../assets/home-banner-bg.svg';
import homeEndingBG from "../assets/home-ending-bg.svg";

function HomePage() {
    const { t } = useTranslation();
    const [showAnalyze, setShowAnalyze] = useState(false);
    const foldRef = useRef();

    const scrollToFold = () => {
        if (foldRef.current) {
            const rect = foldRef.current.getBoundingClientRect();
            window.scrollTo({ top: rect.top, behavior: 'smooth' });
        }
    }

    const toggleAnalyzeDialog = () => {
        setShowAnalyze(prev => !prev);
    }

    const urlSubmitHandler = (event) => {
        const formData = new FormData(event.target);
        const webUrl = formData.get("webUrl");
        
        event.preventDefault();
        sessionStorage.setItem("shortcutWebUrl", webUrl?.trim());
        setShowAnalyze(true);
    }

    return (
        <PageContainer>
            <section className='flex hero' ref={foldRef}>
                <div className='absolute bg-right-top bg-no-repeat hidden lg:block inset-0' style={{backgroundImage: `url(${homeBannerBG})`}}></div>
                <div className='container-def flex grow px-6 relative'>
                    <div className='home-banner-content'>
                        <h1 className='font-semibold text-4xl sm:text-6xl/tight'>
                            {t('HomeBannerTitle')}
                        </h1>

                        <p className='text-gray-def sm:text-lg'>
                            {t('HomeBannerCaption')}
                        </p>
                    
                        <div className='max-w-lg w-full'>
                            <form onSubmit={urlSubmitHandler} className='analyze-url-shortcut-form bg-gray-200 text-sm sm:text-base'>
                                <input
                                    type='url'
                                    name='webUrl'
                                    placeholder={t('InsertURL')}
                                    required
                                />    
                                <button type='submit' className='btn btn-primary'>
                                    <span className='sm:p-3 font-medium'>
                                        {t('HomeBannerButton')}
                                    </span>
                                </button>
                            </form>
                            <div className='w-fit space-y-4 mt-4'>
                                <div className='flex flex-row items-center'>
                                    <span className='inline-divisor grow opacity-25'></span>
                                    <span className='mx-4 text-lg'>
                                        {t('or')}
                                    </span>
                                    <span className='inline-divisor grow opacity-25'></span>
                                </div>
                                <button type='button' onClick={toggleAnalyzeDialog} className='btn btn-secondary rounded-2xl'>
                                    <span className='block font-semibold p-3 sm:px-6'>
                                        {t('AnylyzeFilesShortcut')}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='banner-fucsia py-12'>
                <div className='container-def px-6'>
                    <h2 className='font-medium text-xl sm:text-2xl'>
                        {t('HomeTitle')}
                    </h2>

                    <h3 className='font-semibold text-3xl sm:text-4xl mt-3'>
                        {t("FewSimpleSteps")}
                    </h3>

                    <div className='flex flex-col sm:flex-row gap-12 mt-12'>
                        <div className='flex-1'>
                            <ReactSVG src={insertSiteIC}/>
                            <h3 className='font-bold mt-6 text-2xl'>
                                01 <br/>
                                {t("InsertYourWebsite")}
                            </h3>
                            <p>
                                {t("Upload-caption")}
                            </p>
                        </div>
                        <div className='flex-1'>
                            <ReactSVG src={answareIC}/>
                            <h3 className='font-bold mt-6 text-2xl'>
                                02 <br/>
                                {t("SettingsStep")}
                            </h3>
                            <p>
                                {t("SettingsCaption")}
                            </p>
                        </div>
                        <div className='flex-1'>
                            <ReactSVG src={analysisIC}/>
                            <h3 className='font-bold mt-6 text-2xl'>
                                03 <br/>
                                {t("WeAnalyzeYourWebsite")}
                            </h3>
                            <p>
                                {t("AnalyzeCaption")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <FeaturesBanner/>

            <section className='flex hero overflow-hidden'>
                <div className='absolute bg-top bg-cover hidden lg:block inset-0' style={{backgroundImage: `url(${homeEndingBG})`}}></div>
                <div className='container-def flex grow p-6'>
                    <div className='flex-1 hidden lg:block relative overflow-visible'></div>
                    <div className='flex flex-1 flex-col justify-center items-start'>
                        <div className='max-w-lg mx-auto'>
                            <h2 className='font-semibold text-4xl sm:text-6xl/tight'>
                                {t('TryItNow')}
                            </h2>

                            <p className='mt-6 sm:text-lg'>
                                {t('TryItNowCaption')}
                            </p>
                        
                            <button onClick={scrollToFold} className='btn btn-primary mt-8 rounded-lg text-center w-full'>
                                <span className='block font-semibold py-3 sm:px-3 text-lg sm:text-xl'>
                                    {t('StartFreeTrial')}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {showAnalyze && (
                <DialogOpen>
                    <button type="button" onClick={toggleAnalyzeDialog} className="dialog-close-btn">
                        <ReactSVG src={closeIc} aria-label="Close"/>
                    </button>
                    <div className="dialog-analyze">
                        <AnalyzeForm close={toggleAnalyzeDialog}/>
                    </div>
                </DialogOpen>
            )}

        </PageContainer>
    );
}

export default HomePage;