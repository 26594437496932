import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useGlobalContext } from "../../context/Context";
import PageContainer from "../../components/PageContainer";
import AuthPassInput from "../../components/AuthPassInput";
import backBtnIcon from "../../assets/back-arrow-ic.svg";

function ChangePasswordPage() {
    const { authClient, loggedUser, switchModal } = useGlobalContext();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const navigate = useNavigate();

    const openPassRecovery = () => {
        switchModal("forgotPass");
    }

    const submitHandler = (event) => {
        const formData = new FormData(event.target);
        const fields = Object.fromEntries(formData.entries());
        event.preventDefault();

        if (!fields.currentPassword || !fields.newPassword || fields.newPassword !== fields.passwordConfirmation) {
            setError("IncorrectPassword");
            return;
        }

        if (!loggedUser) {
            setError("AuthRequired");
            return;
        }

        if (!loading) {
            setLoading(true);

            authClient.changePassword(fields.currentPassword, fields.newPassword).then(result => {
                setLoading(false);

                if (!result.success) {
                    setError(result.message);
                    return;
                }

                navigate("/account", {
                    state: { message: "PasswordUpdated" },
                });
            });
        }
    }

    return (
        <PageContainer title={t("ChangePassword")}>
            <section className='container-def px-6 py-12'>
                <div className='flex flex-col sm:flex-row sm:gap-12'>
                    <div className="basis-14">
                        <Link to="/account">
                            <ReactSVG src={backBtnIcon} title="Back"/>
                        </Link>
                    </div>

                    <div className="basis-1/2 flex flex-col gap-6">
                        <h1 className="font-bold sm:mb-6 text-2xl sm:text-4xl">
                            {t('ChangePassword')}
                        </h1>

                        {error && (
                            <p className="text-red-def">
                                {t(error)}
                            </p>
                        )}

                        <form className="contact-form text-sm sm:text-base" onSubmit={submitHandler}>
                            <label>
                                <p>{t('CurrentPassword')}</p>
                                <div className="contact-form-pass">
                                    <AuthPassInput name="currentPassword"/>
                                </div>
                            </label>
                            <label>
                                <p>{t('NewPassword')}</p>
                                <div className="contact-form-pass">
                                    <AuthPassInput name="newPassword"/>
                                </div>
                            </label>
                            <label>
                                <p>{t('ConfirmPassword')}</p>
                                <div className="contact-form-pass">
                                    <AuthPassInput name="passwordConfirmation"/>
                                </div>
                            </label>

                            <div className="contact-form-options">
                                <button type="submit" className='btn btn-primary rounded-lg' disabled={loading}>
                                    <span className='block font-bold p-1'>
                                        {t('ChangePassword')}
                                    </span>
                                </button>
                                <Link to="/account">
                                    {t('Cancel')}
                                </Link>
                                <button type="button" onClick={openPassRecovery} className="link-styled ms-auto">
                                    <span className="font-medium">
                                        {t('ForgotPassword')}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </PageContainer>
    );
}

export default ChangePasswordPage;