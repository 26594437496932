import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ReactSVG } from "react-svg";
import useAnalysisTools from "../../hooks/useAnalysisTools";
import DialogOpen from "../../components/DialogOpen";
import TextFormatter from "../../components/TextFormatter";
import closeIc from '../../assets/x-ic.svg';
import copyIc from '../../assets/copy-ic.svg';
import textSuggIc from "../../assets/text-sugg-ic.svg";

function AnalysisTextSuggestions({ texts = [], isInProgress = false, repeatAnalysis }) {
    const { t } = useTranslation();
    const { parseKeywords } = useAnalysisTools();
    const [tabIndex, setTabIndex] = useState(0);
    const [showRepeatConfirm, setShowRepeatConfirm] = useState(false);
    const [fullTextCopied, setFullTextCopied] = useState(false);
    const [suggTextCopied, setSuggTextCopied] = useState(false);

    const closeNewTextDialog = () => {
        setShowRepeatConfirm(false);
    }

    const copyFullText = (index) => {
        const element = document.querySelector(`[data-suggestion-index="${index}"]`);
        const text = element?.innerText;

        if (navigator.clipboard && text) {
            navigator.clipboard.writeText(text).then(() => {
                setFullTextCopied(true);
    
                setTimeout(() => {
                    setFullTextCopied(false);
                }, 1000);
            });
        }
    }

    const copySuggText = (index) => {
        const text = texts[index]?.body;

        if (navigator.clipboard && text) {
            navigator.clipboard.writeText(text).then(() => {
                setSuggTextCopied(true);

                setTimeout(() => {
                    setSuggTextCopied(false);
                }, 1000);
            });
        }
    }

    const repeatClickHandler = () => {
        if (isInProgress) {
            return;
        }

        if (!showRepeatConfirm) {
            setShowRepeatConfirm(true);
            return;
        }

        if (typeof repeatAnalysis === "function") {
            setShowRepeatConfirm(false);
            repeatAnalysis();
        }
    }

    return (
        <section id="textsuggestions">
            <div className="analysis-box-title">
                <ReactSVG src={textSuggIc}/>
                <h2 className="font-semibold text-2xl">
                    {t("OptimizedContent")}
                </h2>
            </div>

            <Tabs tabIndex={tabIndex} onSelect={setTabIndex} className="flex flex-col sm:flex-row gap-6 mt-6">
                <div className="sm:w-56">
                    <TabList>
                        {[...Array(texts.length)].map((_, index) => (
                            <Tab className="analysis-box flex flex-col p-6 text-center mb-6" key={index}>
                                <span className="font-semibold">
                                    {t("TextSuggestion").replace("%i%", index + 1)}
                                </span>

                                {tabIndex === index ? (
                                    <button type="button" onClick={() => copyFullText(index)} disabled={fullTextCopied} className="btn btn-lightblue font-semibold mt-6 rounded-lg">
                                        {fullTextCopied ? t("TextCopied") : t("CopyAll")}
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-lightblue font-semibold mt-6 rounded-lg">
                                        {t("Show")}
                                    </button>
                                )}
                            </Tab>
                        ))}
                    </TabList>
                    
                    {texts.length < 3 && (
                        <div className="analysis-box flex flex-col p-6 text-center">
                            <span className="font-semibold">
                                {t("TextSuggestion").replace("%i%", texts.length + 1)}
                            </span>
                            <button onClick={repeatClickHandler} disabled={isInProgress} className="btn btn-fucsia font-semibold mt-6 rounded-lg">
                                {isInProgress ? t("Loading") : t("Unlock")}
                            </button>
                        </div>
                    )}
                </div>

                <div className="flex-1">
                    {texts.map((item, index) => (
                        <TabPanel key={index}>
                            <div className="analysis-box px-6 py-4 space-y-4 relative" data-suggestion-index={index}>
                                <div>
                                    <div className="flex flex-wrap gap-2 justify-between items-center mb-3">
                                        <h3 className="font-semibold text-xl text-fucsia-def">
                                            {t("UpdatedText")}
                                        </h3>
                                        <button type="button" onClick={() => copySuggText(index)} disabled={suggTextCopied} className="btn btn-light rounded-lg">
                                            <div className="flex items-center gap-2">
                                                <ReactSVG src={copyIc}/>
                                                <span className="font-medium">
                                                    {suggTextCopied ? t("TextCopied") : t("CopyText")}
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                    <p className="analysis-text-display">
                                        <TextFormatter text={item.body}/>
                                    </p>
                                </div>
                                <div>
                                    <h3 className="mb-3 font-semibold text-xl text-fucsia-def">
                                        {t("NewKeywords")}
                                    </h3>
                                    <p>
                                        {t("NewKeywordsIntro")}
                                    </p>
                                    <p className="analysis-text-display font-medium">{parseKeywords(item.keywords)}</p>
                                </div>
                            </div>
                        </TabPanel>
                    ))}
                </div>
            </Tabs>

            {showRepeatConfirm && (
                <DialogOpen>
                    <button type="button" onClick={closeNewTextDialog} className="dialog-close-btn">
                        <ReactSVG src={closeIc} aria-label="Close"/>
                    </button>
                    <div className="dialog-body">
                        <h2 className="font-medium py-3 px-6 text-center text-lg">
                            {t("GenerateTextDialogTitle")}
                        </h2>
                    </div>
                    <div className="dialog-options border-t text-sm">
                        <button onClick={closeNewTextDialog}>
                            {t("Cancel")}
                        </button>
                        <button onClick={repeatClickHandler} className="btn btn-primary">
                            <span className="block font-semibold py-1">
                                {t("Confirm")}
                            </span>
                        </button>
                    </div>
                </DialogOpen>
            )}
        </section>
    );
}

export default AnalysisTextSuggestions;