import analysisParams from "../resources/analysis-params.json";

function useAnalysisTools() {
    const getDateString = (timestamp) => {
        if (!timestamp) {
            return "InvalidDate";
        }

        const date = new Date(
            timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
        );

        return date.toLocaleDateString();
    }

    const getRatingColor = (rating) => {
        if (rating !== null) {
            if (rating < 30) {
                return "red";
            }
    
            if (rating < 70) {
                return "orange";
            }
    
            if (rating <= 100) {
                return "green";
            }
        }

        return "none";
    }

    const formatHref = (href) => {
        if (typeof href === "string") {
            if (href.startsWith("about:blank")) {
                href = href.slice(11);
            }

            return `<a href="${href}">...</a>`
        }

        return null;
    }

    const limitString = (string, maxLength = 40) => {
        if (string && string.length > maxLength) {
            return string.substring(0, maxLength).concat("...");
        }

        return string;
    }

    const parseAnalysisStatus = (status) => {
        switch (status) {
            case 0:
                return "AnalysisInProgress";
                
            case 1:
                return "AnalysisCompleted";
                
            case 2:
                return "AnalysisError";

            default:
                return `Status: ${status}`;
        }
    }

    const parseKeywords = (kString) => {
        const keywords = [];

        if (typeof kString === "string") {
            kString.split("\n").forEach(kw => {
                keywords.push(kw.trim().replace("- ", ""));
            });
        }
        return keywords.join(", ");
    }

    const parseRating = (ratingString) => {
        if (typeof ratingString === "string") {
            return parseInt(ratingString.replace(/[^0-9]/g, ''));
        }
        
        return null;
    }

    const isWordCountGood = (count) => {
        return count > analysisParams.minWorldCount;
    }

    return {
        getDateString,
        getRatingColor,
        formatHref,
        limitString,
        parseAnalysisStatus,
        parseKeywords,
        parseRating,
        isWordCountGood,
    };
}

export default useAnalysisTools;