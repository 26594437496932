import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import useGoogleSnippetAnalyzer from "../../hooks/useGoogleSnippetAnalyzer";
import Popover from "../../components/Popover";
import urlAnalysisIc from "../../assets/url-analysis-ic.svg";

function AnalysisUrl({ urlAnalysis }) {
    const { t } = useTranslation();
    const gSnippetAnalyzer = useGoogleSnippetAnalyzer();

    const validSlug = urlAnalysis.validSlug;
    const isCrawlable = urlAnalysis.isCrawlable;
    const googleSnippet = urlAnalysis.googleSnippet;
    const sitemap = urlAnalysis.sitemap;
    const canonical = urlAnalysis.canonical;
    const isSeoFriendly = urlAnalysis.seoFriendly;
    const robotsFound = urlAnalysis.robotsFound;
    const isSecure = urlAnalysis.isSecure;

    return (
        <section id="urlAnalysis">
            <div className="analysis-box-title">
                <ReactSVG src={urlAnalysisIc}/>
                <h2 className="font-semibold text-2xl">
                    {t("URLAnalysis")}
                </h2>
            </div>

            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("SeoSlug")}
                    </h3>
                    <Popover>
                        {t("SEOSlugDescription")}
                    </Popover>
                </div>

                {validSlug ? (
                    <p className="analysis-text-success">
                        {t("SeoSlugCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("SeoSlugIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("UrlCrawlable")}
                    </h3>
                    <Popover>
                        {t("CrawlablePageDescription")}
                    </Popover>
                </div>

                {isCrawlable ? (
                    <p className="analysis-text-success">
                        {t("UrlCrawlableCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("UrlCrawlableIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("GoogleSnippet")}
                    </h3>
                    <Popover>
                        {t("GoogleSnippetDescription")}
                    </Popover>
                </div>

                <ul>
                    {gSnippetAnalyzer.checkTitle(googleSnippet.title) ? (
                        <li className="analysis-text-success">
                            {t("GoogleSnippetTitleCorrect")
                                .replace("%min%", gSnippetAnalyzer.minTitleLen)
                                .replace("%max%", gSnippetAnalyzer.maxTitleLen)}
                        </li>
                    ) : (
                        <li className="analysis-text-error">
                            {t("GoogleSnippetTitleIncorrect")}
                        </li>
                    )}
                    
                    {gSnippetAnalyzer.checkMetaDescription(googleSnippet.metaDescription) ? (
                        <li className="analysis-text-success">
                            {t("GoogleSnippetMetaDescriptionCorrect")
                                .replace("%min%", gSnippetAnalyzer.minMetaDescrLen)
                                .replace("%max%", gSnippetAnalyzer.maxMetaDescrLen)}
                        </li>
                    ) : (
                        <li className="analysis-text-error">
                            {t("GoogleSnippetMetaDescriptionIncorrect")
                                .replace("%min%", gSnippetAnalyzer.minMetaDescrLen)
                                .replace("%max%", gSnippetAnalyzer.maxMetaDescrLen)}
                        </li>
                    )}
                    
                    {gSnippetAnalyzer.checkUrl(googleSnippet.url) ? (
                        <li className="analysis-text-success">
                            {t("GoogleSnippetUrlCorrect").replace("%max%", gSnippetAnalyzer.maxUrlLen)}
                        </li>
                    ) : (
                        <li className="analysis-text-error">
                            {t("GoogleSnippetUrlIncorrect").replace("%max%", gSnippetAnalyzer.maxUrlLen)}
                        </li>
                    )}
                </ul>
            </div>

            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Sitemap")}
                    </h3>
                    <Popover>
                        {t("SitemapDescription")}
                    </Popover>
                </div>

                {sitemap?.isOptimized ? (
                    <p className="analysis-text-success">
                        {t("SitemapCorrect").replace("%type%", sitemap.type).replace("%count%", sitemap.length)}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("SitemapIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Canonical")}
                    </h3>
                    <Popover>
                        {t("CanonicalURLDescription")}
                    </Popover>
                </div>

                {canonical?.found && canonical.matches ? (
                    <p className="analysis-text-success">
                        {t("CanonicalCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {(!canonical?.found ? t("CanonicalNotFound") : t("Canonicalncorrect"))
                            .replace("%canonical%", canonical.canonical)
                            .replace("%url%", canonical.url)}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("SeoFriendlyUrl")}
                    </h3>
                    <Popover>
                        {t("SEOFriendlyURLDescription")}
                    </Popover>
                </div>

                {isSeoFriendly ? (
                    <p className="analysis-text-success">
                        {t("SeoFriendlyUrlCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("SeoFriendlyUrlIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Robots")}
                    </h3>
                    <Popover>
                        {t("RobotsDescription")}
                    </Popover>
                </div>

                {robotsFound ? (
                    <p className="analysis-text-success">
                        {t("RobotsCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("RobotsIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("SecureProtocol")}
                    </h3>
                    <Popover>
                        {t("SecureProtocolDescription")}
                    </Popover>
                </div>

                {isSecure ? (
                    <p className="analysis-text-success">
                        {t("SecureUrlCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("SecureUrlIncorrect")}
                    </p>
                )}
            </div>
        </section>
    )
}

export default AnalysisUrl;