import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import contentAnalysisIc from "../../assets/content-analysis-ic.svg";
import metaTagAnalysisIc from "../../assets/metatag-analysis-ic.svg";
import htmlAnalysisIc from "../../assets/html-analysis-ic.svg";
import urlAnalysisIc from "../../assets/url-analysis-ic.svg";
import socialAnalysisIc from "../../assets/social-analysis-ic.svg";
import textSuggIc from "../../assets/text-sugg-ic.svg";

function AnalysisAside() {
    const { t } = useTranslation();
    const asideRef = useRef();

    const scrollToElement = (elementId, offset = 70) => {
        const element = document.getElementById(elementId);
        
        if (element) {
            const rect = element.getBoundingClientRect();
            const top = rect.top + window.scrollY - offset;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        let anchors;

        const clickHandler = (event) => {
            const href = event.currentTarget.getAttribute("href");
            
            if (href?.startsWith("#")) {
                event.preventDefault();
                scrollToElement(href.slice(1));
            }
        }

        if (asideRef.current) {
            anchors = asideRef.current.querySelectorAll("a");

            anchors.forEach(a => {
                a.addEventListener("click", clickHandler);
            });
        }

        return () => {
            anchors?.forEach(a => {
                a.removeEventListener("click", clickHandler);
            });
        }
    }, [asideRef]);
    
    return (
        <aside className="analysis-aside hidden xl:block" ref={asideRef}>
            <ul>
                <li>
                    <a href="#contentAnalysis">
                        <ReactSVG src={contentAnalysisIc}/>
                        <span>{t("ContentAnalysis")}</span>
                    </a>
                </li>
                <li>
                    <a href="#metaTagAnalysis">
                        <ReactSVG src={metaTagAnalysisIc}/>
                        <span>{t("MetaTag")}</span>
                    </a>
                </li>
                <li>
                    <a href="#htmlAnalysis">
                        <ReactSVG src={htmlAnalysisIc}/>
                        <span>{t("HTMLAnalysis")}</span>
                    </a>
                </li>
                <li>
                    <a href="#urlAnalysis">
                        <ReactSVG src={urlAnalysisIc}/>
                        <span>{t("URLAnalysis")}</span>
                    </a>
                </li>
                <li>
                    <a href="#socialAnalysis">
                        <ReactSVG src={socialAnalysisIc}/>
                        <span>{t("SocialTags")}</span>
                    </a>
                </li>
                <li>
                    <a href="#textsuggestions">
                        <ReactSVG src={textSuggIc}/>
                        <span>{t("OptimizedContent")}</span>
                    </a>
                </li>
            </ul>
        </aside>
    );
}

export default AnalysisAside;