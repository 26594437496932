import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import Popover from "../../components/Popover";
import useAnalysisTools from "../../hooks/useAnalysisTools";
import CollapseBox from "../../components/CollapseBox";
import htmlAnalysisIc from "../../assets/html-analysis-ic.svg";

function AnalysisHtml({ htmlAnalysis }) {
    const { t } = useTranslation();
    const { formatHref } = useAnalysisTools();

    const anchorsWithoutTitle = htmlAnalysis.anchorsWithoutTitle;
    const deprecatedTags = htmlAnalysis.deprecatedTags;
    const iframeCount = htmlAnalysis.iframeCount;
    const isHeadingOrdered = htmlAnalysis.isHeadingOrdered;
    const missingMicrodata = htmlAnalysis.missingMicrodata;
    const hasReponsiveDesign = htmlAnalysis.hasResponsiveDesign;
    const hasViewportMetaTag = htmlAnalysis.hasViewportMetaTag;
    const headingCounts = htmlAnalysis.headingCounts;
    const isHTML5Doctype = htmlAnalysis.isHtml5;
    const hasHTML5Elements = htmlAnalysis.hasHtml5Elements;
    const isValidLang = htmlAnalysis.isValidLang;
    const hasLangAttribute = htmlAnalysis.hasLangAttribute;
    const hasFavicon = htmlAnalysis.hasFavicon;
    const hasSecureResources = htmlAnalysis.hasSecureResources;
    const hasImagesAlt = htmlAnalysis.hasImagesAlt;
    const nonDescriptiveAnchors = htmlAnalysis.nonDescriptiveAnchors;

    return (
        <section id="htmlAnalysis">
            <div className="analysis-box-title">
                <ReactSVG src={htmlAnalysisIc}/>
                <h2 className="font-semibold text-2xl">
                    {t("HTMLAnalysis")}
                </h2>
            </div>

            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Iframe")}
                    </h3>
                    <Popover>
                        {t("IFrameDescription")}
                    </Popover>
                </div>

                {iframeCount < 4 ? (
                    <p className="analysis-text-success">
                        {t("IframeCorrect").replace("%count%", iframeCount)}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("IframeIncorrect").replace("%count%", iframeCount)}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Microdata")}
                    </h3>
                    <Popover>
                        {t("microdataDescription")}
                    </Popover>
                </div>

                {missingMicrodata?.length === 0 ? (
                    <p className="analysis-text-success">
                        {t("MicrodataCorrect")}
                    </p>
                ) : (
                    <>
                        <p className="analysis-text-error">
                            {t("MicrodataIncorrect")}
                        </p>
                        <div className="analysis-result-tags mt-2">
                            {missingMicrodata && missingMicrodata.map((htag, index) => (
                                <div key={index}>
                                    <span className="font-semibold">{htag}</span>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Viewport")}
                    </h3>
                    <Popover>
                        {t("ViewportDescription")}
                    </Popover>
                </div>

                {hasReponsiveDesign ? (
                    <p className="analysis-text-success">
                        {t("ViewportCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("ViewportIncorrect")}
                    </p>
                )}
            </div>

            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("ViewportMetaTag")}
                    </h3>
                    <Popover>
                        {t("MetaViewportDescription")}
                    </Popover>
                </div>

                {hasViewportMetaTag ? (
                    <p className="analysis-text-success">
                        {t("MetaViewPortCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("MetaViewPortIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Doctype")}
                    </h3>
                    <Popover>
                        {t("DoctypeDescription")}
                    </Popover>
                </div>

                {isHTML5Doctype ? (
                    <p className="analysis-text-success">
                        {t("DoctypeCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("DoctypeIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("HTML5")}
                    </h3>
                    <Popover>
                        {t("HTML5Description")}
                    </Popover>
                </div>

                {hasHTML5Elements ? (
                    <p className="analysis-text-success">
                        {t("HTML5Correct")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("HTML5Incorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("DeprecatedTags")}
                    </h3>
                    <Popover>
                        {t("DeprecatedTagsDescription")}
                    </Popover>
                </div>

                {deprecatedTags?.length === 0 ? (
                    <p className="analysis-text-success">
                        {t("DeprecatedTagsCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("DeprecatedTagsIncorrect").replace("%tags%", deprecatedTags?.join(", "))}
                    </p>
                )}
            </div>

            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("HTMLLang")}
                    </h3>
                    <Popover>
                        {t("HTMLLangDescription")}
                    </Popover>
                </div>

                <ul>
                    {hasLangAttribute ? (
                        <li className="analysis-text-success">
                            {t("HTMLLangFound")}
                        </li>
                    ) : (
                        <li className="analysis-text-error">
                            {t("LangAttributeIncorrect")}
                        </li>
                    )}

                    {isValidLang ? (
                        <li className="analysis-text-success">
                            {t("HTMLLangValid")}
                        </li>
                    ) : (
                        <li className="analysis-text-error">
                            {t("HTMLLangInvalid")}
                        </li>
                    )}
                </ul>
                
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("LinksDescriptiveText")}
                    </h3>
                    <Popover>
                        {t("LinksDescription")}
                    </Popover>
                </div>

                {nonDescriptiveAnchors?.length === 0 ? (
                    <p className="analysis-text-success">
                        {t("LinksDescriptiveTextCorrect")}
                    </p>
                ) : (
                    <div>
                        <p className="analysis-text-error">
                            {t("LinksDescriptiveTextIncorrect")}
                        </p>
                        <CollapseBox label={t("LinkCollapseLabel")}>
                            <ul className="sm:columns-2 lg:columns-3 break-words text-sm">
                                {nonDescriptiveAnchors?.map((item, index) => (
                                    <li key={index}>{formatHref(item)}</li>
                                ))}
                            </ul>
                        </CollapseBox>
                    </div>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("LinksTitle")}
                    </h3>
                    <Popover>
                        {t("LinkTitleDescription")}
                    </Popover>
                </div>

                {anchorsWithoutTitle?.length === 0 ? (
                    <p className="analysis-text-success">
                        {t("LinksTitleCorrect")}
                    </p>
                ) : (
                    <div>
                        <p className="analysis-text-error">
                            {t("LinksTitleIncorrect")}
                        </p>
                        <CollapseBox label={t("LinkCollapseLabel")}>
                            <ul className="sm:columns-2 lg:columns-3 break-words text-sm">
                                {anchorsWithoutTitle?.map((item, index) => (
                                    <li key={index}>{formatHref(item)}</li>
                                ))}
                            </ul>
                        </CollapseBox>
                    </div>
                )}
            </div>

            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("HeadingOrder")}
                    </h3>
                    <Popover>
                        {t("HeadingOrderDescription")}
                    </Popover>
                </div>

                {isHeadingOrdered ? (
                    <p className="analysis-text-success">
                        {t("HeadingOrderCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {headingCounts?.h1 > 0 ? t("HeadingOrderIncorrect") : t("Heading1Missing")}
                    </p>
                )}

                <div className="analysis-result-tags mt-2">
                    {headingCounts && Object.keys(headingCounts).map((htag, index) => (
                        <div key={index}>
                            <span className="font-semibold">{htag.toUpperCase()}</span>
                            <span className="font-medium"> - {headingCounts[htag]}</span>
                        </div>
                    ))}
                </div>
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("Favicon")}
                    </h3>
                    <Popover>
                        {t("FaviconDescription")}
                    </Popover>
                </div>

                {hasFavicon ? (
                    <p className="analysis-text-success">
                        {t("FaviconCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("FaviconIncorrect")}
                    </p>
                )}
            </div>
            
            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("MixedContents")}
                    </h3>
                    <Popover>
                        {t("MixedContentDescription")}
                    </Popover>
                </div>

                {hasSecureResources ? (
                    <p className="analysis-text-success">
                        {t("MixedContentsCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("MixedContentsIncorrect")}
                    </p>
                )}
            </div>

            <div className="analysis-result-item">
                <div className="analysis-result-title">
                    <h3 className="mb-1 font-medium text-xl">
                        {t("ImagesAlt")}
                    </h3>
                    <Popover>
                        {t("ImageAltDescription")}
                    </Popover>
                </div>

                {hasImagesAlt ? (
                    <p className="analysis-text-success">
                        {t("ImagesAltCorrect")}
                    </p>
                ) : (
                    <p className="analysis-text-error">
                        {t("ImagesAltIncorrect")}
                    </p>
                )}
            </div>
        </section>
    );
}

export default AnalysisHtml;