import { EmailAuthProvider, GoogleAuthProvider, browserLocalPersistence, browserSessionPersistence, createUserWithEmailAndPassword, deleteUser, getAuth, isSignInWithEmailLink, reauthenticateWithCredential, reauthenticateWithPopup, sendEmailVerification, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword, signInWithEmailLink, signInWithPopup, signOut, updatePassword, updateProfile, verifyBeforeUpdateEmail } from "firebase/auth";
import appSettings from '../resources/app-settings.json';

class AuthClient {
    #auth;
    #emailVerificationEnabled;
    #minPasswordLength;
    #googleAuthProvider;

    get auth () {
        return this.#auth;
    }

    get minPasswordLength () {
        return this.#minPasswordLength;
    }

    constructor (fbApp) {
        this.#auth = getAuth(fbApp);
        this.#emailVerificationEnabled = appSettings.sendEmailVerification;
        this.#minPasswordLength = appSettings.minPasswordLength;
        this.#googleAuthProvider = new GoogleAuthProvider();

        this.#auth.useDeviceLanguage();
        this.#googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    }

    #handleError (error, result) {
        if (result && typeof result === "object") {
            result.error = error;
            result.success = false;
            result.message = this.#parseErrorCode(error.code);
            return;
        }

        console.error(error);
    }

    #parseErrorCode (errCode) {
        switch (errCode) {
            case "auth/requires-recent-login":
                return "RecentLoginRequired";

            case "auth/invalid-email":
                return "InvalidEmail";

            case "auth/invalid-credential":
            case "auth/user-mismatch":
                return "IncorrectLogin";

            case "auth/weak-password":
                return "WeakPassword";

            case "auth/user-cancelled":
                return "AuthCancelledUser";

            case "auth/popup-closed-by-user":
                return "AuthPopupClosed";

            default:
                return `Error: ${errCode}`;
        }
    }

    async changePassword (currentPassword, newPassword) {
        const result = {};
        const currentUser = this.#auth.currentUser;
        const authCredentials = EmailAuthProvider.credential(currentUser.email, currentPassword);
        
        try {
            const userCredential = await reauthenticateWithCredential(currentUser, authCredentials);
            await updatePassword(userCredential.user, newPassword);
            result.userCredential = userCredential;
            result.success = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }

        return result;
    }

    async deleteAccount (email, password) {
        const result = {};
        const authCredentials = EmailAuthProvider.credential(email, password);

        try {
            const userCredential = await reauthenticateWithCredential(this.#auth.currentUser, authCredentials);
            await deleteUser(userCredential.user);
            result.success = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }
        
        return result;
    }

    async deleteAccountWithGoogle () {
        const result = {};
        
        try {
            const userCredential = await reauthenticateWithPopup(this.#auth.currentUser, this.#googleAuthProvider);
            await deleteUser(userCredential.user);
            result.success = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }

        return result;
    }

    async login (email, password, keepLogged) {
        const result = {};
        const persistence = keepLogged ? browserLocalPersistence : browserSessionPersistence;

        try {
            await setPersistence(this.#auth, persistence);
            result.userCredential = await signInWithEmailAndPassword(this.#auth, email, password);
            result.success = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }

        return result;
    }

    async loginWithEmailLink (email, link) {
        const result = {};
        const isValidLink = isSignInWithEmailLink(this.#auth, link);

        if (isValidLink) {
            try {
                result.userCredential = await signInWithEmailLink(this.#auth, email, link);
                result.success = true;
            }
            catch (error) {
                this.#handleError(error, result);
            }
        }

        result.isValidLink = isValidLink;
        return result;
    }

    async loginWithGoogle () {
        const result = {};

        try {
            const userCredential = await signInWithPopup(this.#auth, this.#googleAuthProvider);
            result.authResult = GoogleAuthProvider.credentialFromResult(userCredential);
            result.userCredential = userCredential;
            result.success = true;
        }
        catch (error) {
            result.authResult = GoogleAuthProvider.credentialFromError(error);
            this.#handleError(error, result);
        }

        return result;
    }

    async logout () {
        const result = {};

        try {
            await signOut(this.#auth);
            result.success = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }
        
        return result;
    }

    async register (email, password, displayName, keepLogged) {
        const result = {};
        const persistence = keepLogged ? browserLocalPersistence : browserSessionPersistence;

        try {
            await setPersistence(this.#auth, persistence);
            const userCredential = await createUserWithEmailAndPassword(this.#auth, email, password);
            await updateProfile(userCredential.user, { displayName });

            if (this.#emailVerificationEnabled) {
                await sendEmailVerification(userCredential.user);
                result.verificationSent = true;
            }

            result.userCredential = userCredential;
            result.success = true;
        }
        catch (error) {
            if (error.code === "auth/email-already-in-use") {
                return await this.resetPasswordEmail(email);
            }

            this.#handleError(error, result);
        }

        return result;
    }

    async resetPasswordEmail (email) {
        const result = {};
        
        try {
            await sendPasswordResetEmail(this.#auth, email);
            result.success = true;
            result.verificationSent = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }

        return result;
    }

    async updateCurrentProfile (displayName, newEmail) {
        const result = {};
        const currentUser = this.#auth.currentUser;

        try {
            if (currentUser.email !== newEmail) {
                await verifyBeforeUpdateEmail(currentUser, newEmail);
                result.emailUpdated = true;
            }

            await updateProfile(currentUser, { displayName });
            result.success = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }

        return result;
    }

    async verifyEmail () {
        const result = {};

        try {
            await sendEmailVerification(this.#auth.currentUser);
            result.success = true;
        }
        catch (error) {
            this.#handleError(error, result);
        }

        return result;
    }
}

export default AuthClient;